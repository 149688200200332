import React, { useState, useEffect } from 'react';
import sanityClient from './sanityClient';
import { useParams,  } from 'react-router-dom';
import imageUrlBuilder from '@sanity/image-url';
import CryptoTicker from './cryptoTicker';
import MiniArticles from './MiniArticles';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import CategorySidebar from './CategorySidebar';
import './Post.css';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

function SinglePost() {
  const [post, setPost] = useState(null);
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          body,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          categories[]->{title, slug},
          metaTitle,
          metaDescription,
          keywords 
        }`,
        { slug }
      )
      .then((data) => {
        setPost(data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching post:', error);
        setIsLoading(false);
      });

    sanityClient
      .fetch(`*[_type == "post"]{
        _id, title, slug, mainImage{asset->{_id, url}}, publishedAt
      } | order(publishedAt desc)`)
      .then((postData) => {
        setBlogPosts(postData);
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
      });
  }, [slug]);

  if (isLoading) {
    return <p>Loading post...</p>;
  }

  if (!post) {
    return <p>Post not found</p>;
  }

  const h2Titles = post.body
    .filter((block) => block.style === 'h2')
    .slice(0, 2)
    .map((block, index) => (
      <h2 key={index}>{block.children.map((child) => child.text).join(' ')}</h2>
    ));

  return (
    <div className="single-post-container">
      <Helmet>
        <title>{post.metaTitle || post.title || 'Post'}</title>
        <meta name="description" content={post.metaDescription || 'Latest crypto news'} />
        <meta name="keywords" content={post.keywords?.join(', ') || 'crypto, blockchain, news'} />
        <link rel="canonical" href={`https://jsoroko.com/post/${slug}`} />
      </Helmet>

      <div className="post-content-wrapper">
        <div className="post-content-container">
          <div className="single-post">
            <CryptoTicker />

            {h2Titles}

            <h1>{post.title}</h1>

            {post.mainImage && post.mainImage.asset && post.mainImage.asset.url && (
              <img
                src={urlFor(post.mainImage).width(610).height(300).url()}
                alt={post.title || 'Untitled Post'}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  width: '100%',
                  height: '300px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  border: '1px solid #ddd'
                }}
              />
            )}

            <div>
              {post.body && post.body.map((block, index) => {
                if (block.style === 'h1') {
                  return <h1 key={index}>{block.children.map(child => child.text).join(' ')}</h1>;
                }
                if (block.style === 'h2') {
                  return <h2 key={index}>{block.children.map(child => child.text).join(' ')}</h2>;
                }
                if (block.style === 'h3') {
                  return <h3 key={index}>{block.children.map(child => child.text).join(' ')}</h3>;
                }
                if (block.style === 'h4') {
                  return <h4 key={index}>{block.children.map(child => child.text).join(' ')}</h4>;
                }

                if (block.listItem === 'bullet') {
                  return (
                    <ul key={index} style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                      <li>
                        {block.children.map((child, idx) => (
                          <span key={idx}>
                            {child.marks && child.marks.includes('strong') ? (
                              <strong style={{ fontWeight: 'bold' }}>{child.text}</strong>
                            ) : (
                              child.text
                            )}
                          </span>
                        ))}
                      </li>
                    </ul>
                  );
                }

                if (block.listItem === 'number') {
                  return (
                    <ol key={index} style={{ listStyleType: 'decimal', marginLeft: '20px' }}>
                      <li>
                        {block.children.map((child, idx) => (
                          <span key={idx}>
                            {child.marks && child.marks.includes('strong') ? (
                              <strong style={{ fontWeight: 'bold' }}>{child.text}</strong>
                            ) : (
                              child.text
                            )}
                          </span>
                        ))}
                      </li>
                    </ol>
                  );
                }

                if (block._type === 'image' && block.asset) {
                  return (
                    <img
                      key={index}
                      src={urlFor(block.asset).url()}
                      alt={block.alt || 'Image'}
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        borderRadius: '8px',
                        border: '1px solid #ddd'
                      }}
                    />
                  );
                }

                if (block._type === 'block' && block.children) {
                  return (
                    <p key={index}>
                      {block.children.map((child, idx) => {
                        if (child.marks && child.marks.includes('link')) {
                          const linkMark = block.markDefs.find(def => def._key === child.marks[0]);
                          if (linkMark && linkMark.href) {
                            return (
                              <a href={linkMark.href} key={idx} target="_blank" rel="noopener noreferrer">
                                {child.text}
                              </a>
                            );
                          }
                        }

                        if (child.marks && child.marks.includes('strong')) {
                          return <strong key={idx} style={{ fontWeight: 'bold' }}>{child.text}</strong>;
                        }

                        if (child.marks && child.marks.includes('em')) {
                          return <em key={idx}>{child.text}</em>;
                        }

                        return <span key={idx}>{child.text}</span>;
                      })}
                    </p>
                  );
                }

                return null;
              })}
            </div>
          </div>

          <MiniArticles blogPosts={blogPosts} />
        </div>

        <CategorySidebar />
      </div>
      
      <Footer />
    </div>
  );
}

export default SinglePost;
