import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './NftTrading.module.css';

const nftPlatforms = [
    {
        name: "OpenSea",
        description: "The largest NFT marketplace where you can buy, sell, and discover exclusive digital assets.",
        link: "opensea",
        img: "/images/opensea.png"
    },
    {
        name: "Rarible",
        description: "A decentralized platform for NFTs that allows anyone to create, buy, and sell digital collectibles.",
        link: "rarible",
        img: "/images/rarible.png"
    },
    {
        name: "X2Y2",
        description: "A decentralized NFT marketplace offering innovative features for trading and managing digital assets.",
        link: "x2y2",
        img: "/images/x2y2.png"
    },
    {
        name: "Magic Eden",
        description: "A leading NFT marketplace on the Solana blockchain, known for its low fees, fast transactions, and vibrant community of creators and collectors.",
        link: "magiceden",
        img: "/images/magiceden.png"
    },
    {
        name: "Blur",
        description: "Advanced NFT marketplace with real-time data and zero-fee trading, designed for professional traders.",
        link: "blur",
        img: "/images/blur.png"
    },
    {
        name: "OKX NFT Marketplace",
        description: "A comprehensive NFT marketplace by OKX, enabling users to buy, sell, and trade digital collectibles and assets with ease and security.",
        link: "okx",
        img: "/images/okx.png"
    },
    {
        name: "Element",
        description: "A decentralized NFT marketplace with cross-chain support, low fees, and powerful tools for creators and collectors.",
        link: "element",
        img: "/images/element_market.png"
    },
    {
        name: "Tensor",
        description: "A Solana-focused NFT marketplace providing advanced trading tools, cross-chain support, and deep liquidity for professional NFT traders and creators.",
        link: "tensor",
        img: "/images/tensor.png"
    },
    {
        name: "AtomicHub",
        description: "Explore AtomicHub, the leading NFT marketplace on the WAX blockchain, where you can create, buy, sell, and trade a wide variety of digital assets securely and efficiently.",
        link: "atomichub",
        img: "/images/atomichub.png"
    },
    {
        name: "Axie Infinity Marketplace",
       description: "The official marketplace for Axie Infinity, where players can buy, sell, and trade digital pets known as Axies, as well as land, items, and other in-game assets.",
        link: "axieinfinity",
        img: "/images/axie-infinity.png"
    },
    // További platformok adatai itt
];

function NftTrading() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>NFT Trading Platforms | Buy, Sell, and Trade NFTs</title>
                <meta name="description" content="Discover the top NFT trading platforms where you can buy, sell, and trade unique digital assets. Perfect for crypto enthusiasts and collectors." />
                <meta name="keywords" content="NFT trading, buy NFTs, sell NFTs, NFT platforms, digital assets" />
                <link rel="canonical" href="https://www.jsoroko.com/library/nft-trading" />
            </Helmet>
            <header>
                <h1>NFT Trading Platforms</h1>
                <p>Find the most popular platforms for buying, selling, and trading NFTs. Explore these marketplaces to discover unique digital assets and collectibles.</p>
            </header>
            <section className={styles.platformList}>
                {nftPlatforms.map((platform, index) => (
                    <Link to={`/library/nft-trading/${platform.link}`} key={index} className={styles.platformCard}>
                        <img src={platform.img} alt={`${platform.name} Logo`} className={styles.platformImage} loading="lazy" />
                        <h2>{platform.name}</h2>
                        <p>{platform.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default NftTrading;
