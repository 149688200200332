// CryptoGamblingSites.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './CryptoGamblingSites.module.css';

const gamblingPlatforms = [
    {
        name: "Stake",
        description: "Stake is a popular crypto gambling site offering diverse casino games, sports betting, and cryptocurrency rewards.",
        link: "stake",
        img: "/images/stake.png"
    },
    {
        name: "BitStarz",
        description: "BitStarz offers a premium online casino experience with a wide selection of games and exclusive cryptocurrency bonuses.",
        link: "bitstarz",
        img: "/images/bitstarz.png"
    },
    {
        name: "BC.Game",
        description: "BC.Game is a crypto casino offering a vast selection of games, generous bonuses, and a secure, community-driven platform.",
        link: "bc-game",
        img: "/images/bc-game.png"
    },
    {
        name: "FortuneJack",
        description: "FortuneJack offers a comprehensive crypto gambling experience with casino games, sports betting, and generous promotions.",
        link: "fortunejack",
        img: "/images/fortunejack.png"
    },
    {
        name: "Wild.io",
        description: "Wild.io offers a thrilling crypto gambling experience with casino games, sports betting, and unique crypto rewards.",
        link: "wildio",
        img: "/images/wild-io.png"
    },
    {
        name: "Jackbit",
        description: "Jackbit is a versatile crypto casino and sportsbook featuring a wide range of games, fast transactions, and a variety of cryptocurrency options for a seamless experience.",
        link: "jackbit",
    img: "/images/jackbit.png"
    },
    {
        name: "Lucky Block",
        description: "Lucky Block is a cryptocurrency-focused casino featuring thousands of games, fast transactions, and attractive bonuses for crypto players.",
    link: "luckyblock",
    img: "/images/lucky-block.png"
    }
    // Add more gambling sites as needed
];

function CryptoGamblingSites() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>Crypto Gambling Sites | Crypto Casinos and Betting Platforms</title>
                <meta name="description" content="Find the top crypto gambling sites offering a range of casino games, sports betting, and unique crypto bonuses." />
                <meta name="keywords" content="crypto gambling, crypto casinos, online betting, Stake, BitStarz, BC.Game" />
                <link rel="canonical" href="https://www.jsoroko.com/library/crypto-gambling-sites" />
            </Helmet>

            <header>
                <h1>Crypto Gambling Sites</h1>
                <p>Explore the best crypto gambling platforms offering casino games, sports betting, and cryptocurrency rewards.</p>
            </header>

            <section className={styles.gamesList}>
                {gamblingPlatforms.map((platform, index) => (
                    <Link to={`/library/crypto-gambling-sites/${platform.link}`} key={index} className={styles.gameCard}>
                        <img src={platform.img} alt={`${platform.name} Logo`} className={styles.gameImage} loading="lazy" />
                        <h2>{platform.name}</h2>
                        <p>{platform.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default CryptoGamblingSites;
