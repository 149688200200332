import React from 'react'; 
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './NftDetailPage.module.css';

const nftData = [
    {
        name: "OpenSea",
        metaTitle: "OpenSea NFT Marketplace | Largest NFT Platform",
        metaDescription: "Discover OpenSea, the largest NFT marketplace for buying, selling, and exploring exclusive digital assets like art, collectibles, and more.",
        description: "The largest NFT marketplace where you can buy, sell, and discover exclusive digital assets.",
        details: `<p>OpenSea is the premier destination for NFT enthusiasts, artists, and collectors, offering a vast and diverse selection of digital assets. As a peer-to-peer marketplace, OpenSea facilitates easy buying, selling, and exploration of NFTs, which encompass a wide range of categories from art and collectibles to gaming items and virtual worlds.</p>
            
            <h2>About OpenSea</h2>
            <p>Launched in 2017, OpenSea was one of the earliest platforms to pioneer the trading of NFTs on the blockchain. Since its inception, it has become a leading marketplace for both new and established NFT artists, as well as collectors looking to discover unique digital items. OpenSea supports a multi-chain ecosystem, including popular blockchains like Ethereum, Polygon, and Klaytn, enabling seamless interoperability and expanded access to a broader NFT audience.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Diverse Range of NFTs:</strong> Explore a wide variety of digital assets, including art, virtual worlds, domain names, and trading cards. OpenSea's extensive categories make it easy to discover niche collectibles and rare items.</li>
                <li><strong>Multi-Chain Support:</strong> OpenSea supports multiple blockchain networks, primarily Ethereum, Polygon, and Klaytn, allowing users to access various assets without leaving the platform.</li>
                <li><strong>Flexible Marketplace Options:</strong> The platform provides both direct sales and bidding options, giving buyers and sellers more flexibility in how they interact with listings and settle transactions.</li>
                <li><strong>Comprehensive Tools for Creators:</strong> Artists and creators can mint, list, and manage their NFT collections directly through OpenSea's creator tools. This includes options to add royalties and control over sales, ensuring creators retain value from their work over time.</li>
                <li><strong>User-Friendly Interface:</strong> With a simple and intuitive interface, OpenSea makes it easy for newcomers and seasoned traders alike to navigate the marketplace, search for specific NFTs, and monitor trends.</li>
            </ul>
    
            <h2>Community and Education</h2>
            <p>OpenSea also invests in educational resources and a robust community, providing tools, guides, and events that help users understand the evolving NFT space. From newcomers learning about NFTs to experienced collectors tracking trends, OpenSea's resources cater to every level of expertise.</p>
    
            <p>As the largest and most well-established NFT marketplace, OpenSea continues to shape the digital art and collectible landscape, offering a reliable and innovative platform for discovering, trading, and managing exclusive digital assets.</p>`, // OpenSea details here
        link: "https://opensea.io",
        img: "/images/opensea.png"
    },
    {
        name: "Rarible",
        metaTitle: "Rarible NFT Marketplace | Community-Driven Platform",
        metaDescription: "Explore Rarible, a decentralized NFT marketplace that empowers creators and collectors with community governance and multi-chain support.",
        description: "A community-driven NFT marketplace for buying, selling, and creating unique digital assets.",
        details: `<p>Rarible is a leading platform for NFT enthusiasts, artists, and creators, providing a user-friendly space for creating, buying, and selling NFTs. Known for its community-driven approach, Rarible empowers users with governance rights through its native token, RARI, allowing them to directly influence platform developments and policies.</p>
    
            <h2>About Rarible</h2>
            <p>Founded in 2019, Rarible quickly gained popularity as a decentralized NFT marketplace where anyone can easily mint, trade, and explore digital collectibles and artworks. With support for multiple blockchain networks, including Ethereum, Polygon, Tezos, and Flow, Rarible offers a flexible ecosystem for both creators and collectors. It has grown into a vibrant community with a wide range of digital assets, from unique art pieces to music and gaming items.</p>
    
            <h2>Main Features</h2>
            <ul>
                <li><strong>Multi-Chain Support:</strong> Rarible enables NFT trading across various blockchains, such as Ethereum, Polygon, Tezos, and Flow. This multi-chain capability allows users to mint and purchase NFTs on different networks, broadening the platform's accessibility.</li>
                <li><strong>Creator-Friendly Minting Tools:</strong> With an intuitive minting process, Rarible allows creators to easily turn their works into NFTs without requiring deep technical knowledge. The "lazy minting" feature even enables creators to list their NFTs without paying upfront gas fees, transferring the cost to the buyer upon purchase.</li>
                <li><strong>Decentralized Governance:</strong> Rarible's native token, RARI, grants holders the ability to participate in the platform's governance, voting on key decisions and future developments, ensuring a community-driven approach to growth.</li>
                <li><strong>Flexible Royalties System:</strong> Rarible supports customizable royalties, allowing creators to set a percentage of resale value to earn passive income as their creations are resold, giving artists more control over long-term earnings.</li>
                <li><strong>Comprehensive Marketplace:</strong> The platform features a variety of listing options, including fixed-price sales and auctions, enabling buyers and sellers to engage in diverse transaction types.</li>
            </ul>
    
            <h2>Community and Innovation</h2>
            <p>Rarible is strongly focused on fostering a sense of community. It offers educational resources, events, and collaborative opportunities, encouraging artists, collectors, and developers to connect and shape the future of the NFT space. By combining innovative features with community engagement, Rarible remains at the forefront of the decentralized NFT marketplace movement.</p>
    
            <p>As a platform that champions creator rights and decentralized governance, Rarible has become a unique player in the NFT industry, offering a comprehensive and community-oriented space for exploring, creating, and trading digital assets.</p>`, // Rarible details here
        link: "https://rarible.com/",
        img: "/images/rarible.png"
    },
    {
        name: "X2Y2",
        metaTitle: "X2Y2 NFT Marketplace | Decentralized Platform for NFT Trading",
        metaDescription: "Join X2Y2, a decentralized NFT platform with unique features for bulk listing, rarity integration, and NFT lending for community-driven trading.",
        description: "A decentralized NFT marketplace offering innovative features for trading and managing digital assets.",
        details: ` <p>X2Y2 is a community-driven NFT marketplace designed to provide a fully decentralized platform for buying, selling, and lending NFTs. Launched in January 2022, X2Y2 aims to address the limitations of existing marketplaces by offering enhanced features and a fairer distribution of rewards among its users.</p>
            
            <h2>About X2Y2</h2>
            <p>Developed by a team of experienced blockchain professionals, X2Y2 was created to offer a truly decentralized NFT market owned by the community. The platform emphasizes transparency and fairness, with no private token sales and a commitment to sharing 100% of market fees with X2Y2 token stakers. This approach ensures that users are directly rewarded for their participation and contributions to the ecosystem.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Bulk Listing and Batch Purchasing:</strong> X2Y2 allows users to list multiple NFTs in a single transaction and purchase multiple NFTs at once, saving time and reducing gas fees.</li>
                <li><strong>Real-Time Notifications:</strong> Users receive instant notifications and emails for their items, keeping them informed about offers, sales, and other activities.</li>
                <li><strong>Rarity Integration:</strong> The platform provides built-in tools to browse NFT trait rarities without the need for external extensions, enhancing the discovery process for collectors.</li>
                <li><strong>Creator Tools:</strong> X2Y2 offers a comprehensive set of tools for creators, including whitelist minting and royalty management, with real-time royalty payments at the point of sale.</li>
                <li><strong>NFT Lending:</strong> The platform operates as an NFT Fi platform, allowing users to borrow ETH from lenders using their NFTs as collateral, expanding the financial utility of digital assets.</li>
            </ul>
            
            <h2>Community and Governance</h2>
            <p>X2Y2 is committed to building a community-owned platform. The X2Y2 token serves as both a governance and utility token, enabling holders to participate in decision-making processes and earn a share of the platform's revenue through staking. This model ensures that the community has a direct influence on the platform's development and direction.</p>
            
            <p>By focusing on decentralization, user empowerment, and innovative features, X2Y2 offers a compelling alternative in the NFT marketplace landscape, catering to the needs of both creators and collectors.</p>`, // X2Y2 details here
        link: "https://x2y2.io",
        img: "/images/x2y2.png"
    },
    {
        name: "Magiceden",
        metaTitle: "Magic Eden NFT Marketplace | Leading Solana NFT Platform",
        metaDescription: "Explore Magic Eden, a premier NFT marketplace on the Solana blockchain, offering exclusive digital assets, low fees, and a thriving community.",
        description: "A leading NFT marketplace on the Solana blockchain, known for its low fees, fast transactions, and vibrant community of creators and collectors.",
        details: `
            <p>Magic Eden is a top NFT marketplace focused on the Solana blockchain, providing an affordable, fast, and community-oriented platform for trading digital assets. Known for its minimal transaction fees and rapid transaction times, Magic Eden has become a popular choice among NFT enthusiasts and Solana supporters alike.</p>
            
            <h2>About Magic Eden</h2>
            <p>Launched in 2021, Magic Eden quickly rose to prominence as one of the largest Solana-based NFT marketplaces. By leveraging Solana's high-performance blockchain, Magic Eden offers low fees and high-speed transactions, creating an ideal environment for artists and collectors looking for a cost-effective way to trade NFTs. With a focus on accessibility and community engagement, Magic Eden has established itself as a key player in the Solana NFT ecosystem.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Solana-Based Marketplace:</strong> Magic Eden utilizes the Solana blockchain, enabling fast, low-cost transactions for NFT trading, which is particularly appealing to users looking for affordability and efficiency.</li>
                <li><strong>Exclusive NFT Drops:</strong> The platform regularly hosts exclusive NFT drops and collections, allowing users to discover and acquire limited-edition assets.</li>
                <li><strong>Launchpad for Creators:</strong> Magic Eden offers a dedicated launchpad for creators to mint and launch their NFTs directly on the platform, helping artists reach a wider audience.</li>
                <li><strong>Community-Centric:</strong> The platform fosters a strong community through events, collaborations, and social engagement, making it a hub for Solana NFT enthusiasts.</li>
                <li><strong>Marketplace and Auction Options:</strong> Users can choose between fixed-price listings and auctions, offering flexible ways to buy and sell NFTs on the platform.</li>
            </ul>
            
            <h2>Community and Accessibility</h2>
            <p>Magic Eden is dedicated to creating a welcoming environment for all users, from new NFT collectors to experienced traders. With regular community events, educational content, and collaborations with prominent Solana projects, Magic Eden fosters a strong sense of community and support within the ecosystem.</p>
            
            <p>As one of the most trusted Solana-based NFT marketplaces, Magic Eden continues to innovate and offer value to the NFT community, making it a go-to platform for discovering, trading, and creating digital assets on Solana.</p>
        `,
        link: "https://magiceden.io",
        img: "/images/magiceden.png"
    },
    {
        name: "Blur",
        metaTitle: "Blur NFT Marketplace | Advanced Trading for Professional Collectors",
        metaDescription: "Discover Blur, an advanced NFT marketplace designed for pro traders with real-time data, analytics, and a zero-fee trading environment.",
        description: "An advanced NFT marketplace focused on professional traders, offering real-time data, analytics, and a zero-fee trading experience.",
        details: `
            <p>Blur is a cutting-edge NFT marketplace built specifically for experienced traders and collectors. Known for its real-time analytics, high-speed transactions, and professional trading tools, Blur provides an optimized environment for users looking to gain an edge in the NFT market.</p>
            
            <h2>About Blur</h2>
            <p>Launched in 2022, Blur quickly gained attention as a platform tailored to the needs of professional NFT traders. It offers powerful tools for analyzing and trading NFTs, with a focus on speed, precision, and data transparency. With no trading fees and enhanced analytics, Blur empowers users to make informed decisions in the fast-paced world of NFTs.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Zero-Fee Trading:</strong> Blur offers a zero-fee trading environment, allowing traders to maximize their profits without worrying about transaction costs.</li>
                <li><strong>Real-Time Data and Analytics:</strong> The platform provides live data, including floor price tracking, volume analysis, and market trends, helping traders stay informed in real time.</li>
                <li><strong>Portfolio Management:</strong> Blur includes portfolio tracking tools, giving users a clear overview of their NFT assets and trading history in one place.</li>
                <li><strong>Advanced Trading Tools:</strong> With features like batch listings and fast transaction processing, Blur is designed to facilitate large-volume trading and complex strategies.</li>
                <li><strong>Community-Driven Platform:</strong> Blur regularly collaborates with its community, offering incentives, governance opportunities, and exclusive features for active traders.</li>
            </ul>
            
            <h2>Community and Professional Focus</h2>
            <p>Blur is dedicated to creating a high-performance, professional trading environment for NFT enthusiasts. By focusing on tools that enhance trading efficiency and providing access to comprehensive market data, Blur has positioned itself as a go-to marketplace for experienced traders seeking an edge.</p>
            
            <p>As one of the few NFT marketplaces built for high-speed, data-driven trading, Blur continues to attract a community of serious collectors and traders who prioritize real-time insights and a seamless trading experience.</p>
        `,
        link: "https://blur.io",
        img: "/images/blur.png"
    },
    {
        name: "OKX",
        metaTitle: "OKX NFT Marketplace | Buy, Sell, and Explore NFTs on OKX",
        metaDescription: "Explore the OKX NFT Marketplace, where you can buy, sell, and trade a wide range of digital collectibles and assets on a secure and user-friendly platform.",
        description: "A comprehensive NFT marketplace by OKX, enabling users to buy, sell, and trade digital collectibles and assets with ease and security.",
        details: `
            <p>OKX NFT Marketplace is a versatile platform designed to make NFT trading accessible and secure. Backed by the trusted OKX brand, this marketplace provides users with a streamlined experience for discovering, buying, and selling NFTs across multiple categories, including art, gaming items, and collectibles.</p>
            
            <h2>About OKX NFT Marketplace</h2>
            <p>Launched as an extension of the OKX ecosystem, the OKX NFT Marketplace benefits from OKX's expertise in digital asset trading. The platform leverages OKX's robust security protocols and user-friendly interface to ensure a safe and efficient NFT trading experience. With its broad selection of NFTs and cross-chain support, OKX is positioned as a go-to marketplace for diverse digital assets.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Cross-Chain Support:</strong> OKX NFT Marketplace supports multiple blockchain networks, including Ethereum, BSC, and OKExChain, allowing users to access a diverse range of NFTs across chains.</li>
                <li><strong>Secure and Reliable:</strong> Built on OKX’s infrastructure, the marketplace provides enhanced security features, including two-factor authentication, to protect users’ assets.</li>
                <li><strong>Wide Range of Categories:</strong> From digital art and collectibles to in-game assets, OKX NFT Marketplace offers NFTs in various categories to cater to different interests.</li>
                <li><strong>Low Transaction Fees:</strong> The platform offers competitive transaction fees, making it accessible to a wide range of users.</li>
                <li><strong>Creator-Friendly Tools:</strong> OKX provides tools for artists and creators to mint and manage their NFTs easily, empowering them to reach a global audience.</li>
            </ul>
            
            <h2>Community and Accessibility</h2>
            <p>OKX is committed to building a user-friendly and inclusive NFT marketplace. The platform frequently engages with its community, offering resources and events to educate users about NFTs and blockchain technology. With a focus on accessibility and security, OKX NFT Marketplace is an ideal platform for both beginners and seasoned traders.</p>
            
            <p>As part of the OKX ecosystem, the OKX NFT Marketplace leverages the expertise and trustworthiness of one of the leading digital asset exchanges, providing a reliable and efficient space for NFT trading.</p>
        `,
        link: "https://www.okx.com/web3/marketplace/nft",
        img: "/images/okx.png"
    },
    {
        name: "Element",
        metaTitle: "Element Market NFT Platform | Cross-Chain NFT Trading",
        metaDescription: "Explore Element Market, a decentralized NFT marketplace with cross-chain support, low fees, and creator-focused tools for seamless digital asset trading.",
        description: "A decentralized NFT marketplace with cross-chain support, low fees, and powerful tools for creators and collectors.",
        details: `
            <p>Element Market is a decentralized NFT marketplace that supports multiple blockchains, offering a flexible and low-cost platform for trading digital assets. With its advanced tools and cross-chain compatibility, Element Market provides a streamlined experience for NFT collectors and creators alike.</p>
            
            <h2>About Element Market</h2>
            <p>Element Market is designed to facilitate efficient NFT trading across several blockchain networks, including Ethereum, BNB Chain, Polygon, and Avalanche. Known for its low transaction fees and creator-friendly features, the platform allows users to access a broader NFT market with cost-effective transactions.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Cross-Chain Compatibility:</strong> Element Market enables NFT trading across multiple blockchains, including Ethereum, BNB Chain, Polygon, and Avalanche, providing users with access to a wider range of NFTs.</li>
                <li><strong>Low Fees:</strong> The platform utilizes optimized smart contracts and batch order functions, which can reduce gas fees by up to 49%, making it a cost-effective choice for users.</li>
                <li><strong>Creator-Focused Tools:</strong> Element Market offers various tools for creators, including easy NFT creation, customizable royalties, and audience engagement features, empowering artists to monetize their work effectively.</li>
                <li><strong>Secure Decentralized Platform:</strong> Built on blockchain technology, Element Market prioritizes security and transparency, ensuring safe transactions for all users.</li>
                <li><strong>Community Engagement:</strong> The platform actively supports community-driven initiatives and events, fostering a vibrant and engaged ecosystem for NFT enthusiasts.</li>
            </ul>
            
            <h2>Community and Decentralization</h2>
            <p>Element Market is committed to maintaining a decentralized and community-oriented platform. With regular updates, community events, and collaborations, Element Market fosters a supportive environment for both new and experienced users in the NFT space.</p>
            
            <p>With its focus on low fees, cross-chain support, and tools that empower creators, Element Market is a robust platform for NFT enthusiasts looking for flexibility and innovation in digital asset trading.</p>
        `,
        link: "https://element.market/ethereum",
        img: "/images/element_market.png"
    },
    {
        name: "Tensor",
        metaTitle: "Tensor NFT Marketplace | Advanced Trading on Solana",
        metaDescription: "Discover Tensor, the leading Solana-based NFT marketplace offering advanced trading features, real-time data, and deep liquidity for pro traders and collectors.",
        description: "A Solana-focused NFT marketplace providing advanced trading tools, cross-chain support, and deep liquidity for professional NFT traders and creators.",
        details: `
            <p>Tensor is a cutting-edge NFT marketplace built for advanced traders and collectors on the Solana blockchain. Known for its real-time analytics, liquidity solutions, and sophisticated trading tools, Tensor is quickly becoming the go-to marketplace for Solana NFTs.</p>
            
            <h2>About Tensor NFT Marketplace</h2>
            <p>Launched in July 2022, Tensor has become one of the largest NFT marketplaces on Solana, handling a significant portion of the network's NFT trading volume. The platform offers tools designed for professional trading, including collection-wide bids, market-making orders, and live data updates.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Advanced Trading Tools:</strong> Tensor provides professional-grade tools such as collection-wide bids, order books, and real-time data to help traders make informed decisions.</li>
                <li><strong>Deep Liquidity:</strong> With high trading volumes and liquidity solutions, Tensor enables efficient NFT trading and reduced slippage for large transactions.</li>
                <li><strong>Multi-Chain Expansion Plans:</strong> While Tensor primarily operates on Solana, it has plans to expand to other blockchains, making it accessible to a broader user base in the future.</li>
                <li><strong>Creator-Friendly Platform:</strong> Tensor offers a suite of tools for creators, including a launchpad for Solana NFTs and a dedicated portal for managing collections.</li>
                <li><strong>Community Engagement:</strong> Tensor has launched its own NFT collection, Tensorians, to engage its community and provide added benefits to active users.</li>
            </ul>
            
            <h2>Community and Growth</h2>
            <p>Tensor is dedicated to building a community-driven platform with regular events, updates, and a commitment to empowering NFT traders and creators. The platform's strong focus on professional trading features and community engagement makes it a vital player in the Solana NFT ecosystem.</p>
            
            <p>With its advanced trading features, liquidity, and upcoming cross-chain support, Tensor continues to innovate within the Solana NFT market, providing both traders and creators with a robust, high-performance platform.</p>
        `,
        link: "https://www.tensor.trade",
        img: "/images/tensor.png"
    },
    {
        name: "AtomicHub",
        metaTitle: "AtomicHub NFT Marketplace | Buy, Sell, and Create on WAX Blockchain",
        metaDescription: "Explore AtomicHub, the leading NFT marketplace on the WAX blockchain, where you can create, buy, sell, and trade a wide variety of digital assets securely and efficiently.",
        description: "A WAX-based NFT marketplace offering low fees, cross-chain compatibility, and a user-friendly platform for creating, buying, and selling NFTs.",
        details: `
            <p>AtomicHub is a popular NFT marketplace built on the WAX blockchain, known for its user-friendly interface, low fees, and extensive selection of digital assets. Designed to cater to both creators and collectors, AtomicHub provides an efficient platform for NFT trading, creation, and management.</p>
            
            <h2>About AtomicHub NFT Marketplace</h2>
            <p>AtomicHub was created to offer a seamless NFT trading experience on the WAX blockchain. The platform supports a variety of digital collectibles, from gaming items and digital art to unique collectibles, allowing users to explore and trade NFTs across multiple blockchains.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Diverse NFT Collections:</strong> AtomicHub hosts a wide array of NFTs, including digital art, gaming assets, and collectibles, making it a popular choice among collectors.</li>
                <li><strong>NFT Creation Tools:</strong> The platform offers a user-friendly NFT Creator tool that enables users to mint and manage their own digital assets without requiring extensive technical knowledge.</li>
                <li><strong>Cross-Chain Support:</strong> AtomicHub allows users to access NFTs from multiple blockchains, including WAX, EOS, and Immutable X, within a single marketplace.</li>
                <li><strong>Low Transaction Fees:</strong> With optimized smart contracts, AtomicHub provides a cost-effective solution for NFT transactions on the WAX blockchain.</li>
                <li><strong>Community Engagement:</strong> AtomicHub actively fosters a vibrant community of creators and collectors through events, resources, and a supportive ecosystem.</li>
            </ul>
            
            <h2>Community and Accessibility</h2>
            <p>AtomicHub is committed to creating a secure and inclusive platform for the NFT community. With a focus on user accessibility, low fees, and a range of creation tools, AtomicHub continues to be a top choice for both new and experienced users in the NFT space.</p>
            
            <p>Whether you're looking to mint your own NFTs or explore a diverse selection of digital assets, AtomicHub offers a comprehensive platform that meets the needs of NFT enthusiasts on the WAX blockchain.</p>
        `,
        link: "https://wax.atomichub.io/",
        img: "/images/atomichub.png"
    },
    {
        name: "Axieinfinity",
        metaTitle: "Axie Infinity Marketplace | Buy, Sell, and Trade Digital Pets",
        metaDescription: "Explore the Axie Infinity Marketplace to buy, sell, and trade unique digital pets called Axies. Engage in battles, breeding, and a vibrant community-driven economy.",
        description: "The official marketplace for Axie Infinity, where players can buy, sell, and trade digital pets known as Axies, as well as land, items, and other in-game assets.",
        details: `
            <p>The Axie Infinity Marketplace is the central hub for all transactions within the Axie Infinity universe. Players can purchase, sell, and trade Axies—digital creatures used in battles and breeding—alongside land plots, items, and other in-game assets. This marketplace is integral to the game's play-to-earn model, allowing users to earn real-world value through gameplay.</p>
            
            <h2>About Axie Infinity Marketplace</h2>
            <p>Launched by Sky Mavis, the Axie Infinity Marketplace operates on the Ronin sidechain, an Ethereum-linked blockchain developed to reduce transaction fees and enhance scalability. This design ensures a seamless and cost-effective trading experience for users. The marketplace supports various assets, including Axies, land plots, items, and bundles, facilitating a comprehensive in-game economy.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Extensive Asset Listings:</strong> Browse a wide array of Axies, each with unique traits and abilities, as well as land plots and items that enhance gameplay.</li>
                <li><strong>Secure Transactions:</strong> Utilizing the Ronin sidechain, the marketplace offers secure and efficient transactions with minimal fees.</li>
                <li><strong>Breeding and Collecting:</strong> Engage in breeding Axies to create new offspring with distinct characteristics, adding depth to the collecting experience.</li>
                <li><strong>Community Engagement:</strong> Participate in a vibrant community-driven economy, where player interactions and market dynamics shape the in-game ecosystem.</li>
                <li><strong>Play-to-Earn Opportunities:</strong> Leverage the marketplace to monetize gameplay by selling in-game assets, contributing to the play-to-earn model.</li>
            </ul>
            
            <h2>Community and Growth</h2>
            <p>Axie Infinity has cultivated a robust global community, with players actively participating in governance and development through the Axie Infinity Shards (AXS) token. The marketplace plays a pivotal role in this ecosystem, enabling players to trade assets and engage in economic activities that drive the game's growth and sustainability.</p>
            
            <p>As a pioneer in blockchain gaming, the Axie Infinity Marketplace exemplifies the potential of decentralized economies within virtual worlds, offering players both entertainment and financial opportunities.</p>
        `,
        link: "https://app.axieinfinity.com/marketplace/",
        img: "/images/axie-infinity.png"
    }
    
    
    
    // Additional platforms can be added here.
];

function NftDetailPage() {
    const { platform } = useParams();
    const platformDetail = nftData.find(item => item.name.toLowerCase() === platform);

    if (!platformDetail) {
        return <p>Platform not found.</p>;
    }

    return (
        <div className={styles.detailContainer}>
            <Helmet>
                <title>{platformDetail.metaTitle}</title>
                <meta name="description" content={platformDetail.metaDescription} />
                <meta name="keywords" content={`${platformDetail.name}, NFT marketplace, NFT trading, digital assets, ${platformDetail.name} platform`} />
                <link rel="canonical" href={`https://www.jsoroko.com/library/nft-trading/${platform}`} />
            </Helmet>
            <img src={platformDetail.img} alt={`${platformDetail.name} Logo`} className={styles.detailImage} loading="lazy" />
            <h1>{platformDetail.name}</h1>
            <p className={styles.detailDescription}>{platformDetail.metaDescription}</p>
            <div
                className={styles.detailContent}
                dangerouslySetInnerHTML={{ __html: platformDetail.details }}
            />
            <a
                href={platformDetail.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.detailLink}
            >
                Visit {platformDetail.name}
            </a>
        </div>
    );
}

export default NftDetailPage;
