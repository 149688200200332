import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
  const [centralCards, setCentralCards] = useState([]);
  const [cards, setCards] = useState([]);
  const location = useLocation();
  const canonicalUrl = `https://jsoroko.com${location.pathname}`;

  useEffect(() => {
    setCentralCards([
      {
        title: 'Build Your MemeCoin',
        content: 'Create your MemeCoin effortlessly on the Base network and bring your unique idea to life with ease and confidence.',
        linkUrl: '/base-network',
        subCards: [
          {
            name: 'Create Memecoin',
            linkUrl: '/base-network',
          },
        ],
      },
      {
        title: 'Get the Latest in Crypto News',
        content: 'Explore breaking news and in-depth stories about the world of cryptocurrency and memecoins.',
        linkUrl: '/crypto-news',
        subCards: [
          {
            name: 'Crypto News',
            linkUrl: '/crypto-news',
          },
        ],
      },
      {
        title: 'Crypto Resource Library',
        content: 'Discover top crypto APIs, NFT platforms, P2E games, and more. Enhance your experience with essential tools.',
        linkUrl: '/library',
        subCards: [
          {
            name: 'Library',
            linkUrl: '/library',
          },
        ],
      },
    ]);

    setCards([
      {
        title: 'Effortless Creation',
        content: 'Stay updated with the latest cryptocurrency news! Explore comprehensive articles and in-depth analysis covering major events and trends in the crypto world.',
      },
      {
        title: 'Expand Your Brand',
        content: 'Bring your unique idea to life effortlessly on the Base network. Use our platform to create a personalized MemeCoin without coding skills, tailored to resonate with your audience.',
      },
      {
        title: 'Stand Out in the Crypto World',
        content: 'Discover essential tools and platforms across the crypto ecosystem, including top APIs, NFT trading, Play-to-Earn games, and DeFi resources to enhance your crypto journey.',
      },
    ]);
  }, []);

  return (
    <div className="homepage-container">
      <Helmet>
        <title>jsoroko.com - Your Hub for All Things Crypto</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content="Explore jsoroko.com to create your own crypto project effortlessly, stay informed with the latest crypto news, and access essential tools for your digital finance journey." />
        <meta name="keywords" content="cryptocurrency, DeFi, blockchain, crypto news, MemeCoin creator, NFT platforms, Play-to-Earn games" />
      </Helmet>
      <header className="homepage-header">
        <h1>Welcome to jsoroko.com – Your Hub for All Things Crypto!</h1>
      </header>
      <div className="content-wrapper">
       
        <div className="content">
          <div className="main-content">
            <h2>Step into the World of Crypto, DeFi, and Beyond!</h2>
            <p>
              Discover a dynamic platform where creating your own MemeCoin is effortless on the Base network—bring your unique idea to life with ease. Stay updated with the latest crypto news and dive into in-depth stories about MemeCoins and the broader cryptocurrency world. Explore our Crypto Resource Library to discover top crypto APIs, NFT platforms, Play-to-Earn games, and more. Stay connected, informed, and entertained as you navigate the ever-evolving world of digital finance.
            </p>
          </div>

          {/* Központi nagyobb kártyák */}
          <div className="central-card-container">
            {centralCards.length > 0 && centralCards.map((card, index) => (
              <div key={index} className="central-card">
                <h4>{card.title}</h4>
                <p>{card.content}</p>
                <div className="sub-card-container">
                  {card.subCards.map((subCard, subIndex) => (
                    <a
                      key={subIndex}
                      href={subCard.linkUrl}
                      className="sub-card"
                    >
                      <p>{subCard.name}</p>
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Jobb oldali kártyák */}
          <div className="card-container">
            {cards.length > 0 && cards.map((card, index) => (
              <div key={index} className="info-card">
                <h4>{card.title}</h4>
                <p>{card.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div> {/* content-wrapper vége */}
      
    </div>
  );
};

export default HomePage;
