import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Footer from './Footer'; // Footer komponens importálása

const cryptoOptions = {
  bitcoin: 'Bitcoin',
  ethereum: 'Ethereum',
  litecoin: 'Litecoin',
  dogecoin: 'Dogecoin',
  solana: 'Solana',
  tether: 'USDT',
  usdc: 'USDC',
  xrp: 'XRP',
  cardano: 'Cardano',
  tron: 'Tron',
};

const fiatOptions = {
  usd: 'USD',
  eur: 'EUR',
  gbp: 'GBP',
  jpy: 'JPY',
  rub: 'RUB',
  krw: 'KRW',
  try: 'TRY',
  brl: 'BRL',
  cny: 'CNY',
};

const CryptoConverter = () => {
  const [crypto, setCrypto] = useState('bitcoin');
  const [fiat, setFiat] = useState('usd');
  const [cryptoAmount, setCryptoAmount] = useState('');
  const [fiatAmount, setFiatAmount] = useState('');
  const [rate, setRate] = useState(null);
  const [reverse, setReverse] = useState(false);

  const location = useLocation();
  const canonicalUrl = `https://jsoroko.com${location.pathname}`;

  useEffect(() => {
    if (crypto && fiat) {
      axios
        .get(`https://api.coingecko.com/api/v3/simple/price?ids=${crypto}&vs_currencies=${fiat}`)
        .then((response) => {
          setRate(response.data[crypto][fiat]);
        })
        .catch((error) => console.error('Error fetching rate:', error));
    }
  }, [crypto, fiat]);

  const handleCryptoAmountChange = (e) => {
    setCryptoAmount(e.target.value);
    setFiatAmount(e.target.value * rate);
  };

  const handleFiatAmountChange = (e) => {
    setFiatAmount(e.target.value);
    setCryptoAmount(e.target.value / rate);
  };

  const toggleConversion = () => {
    setReverse(!reverse);
    setCryptoAmount('');
    setFiatAmount('');
  };

  return (
    <>
      <div className="converter-container">
        <Helmet>
          <title>Crypto Converter - Convert Cryptocurrencies to Fiat</title>
          <link rel="canonical" href={canonicalUrl} />
          <meta
            name="description"
            content="Convert cryptocurrencies like Bitcoin, Ethereum, Solana and Litecoin to fiat currencies such as USD, EUR, and more. Get real-time rates powered by CoinGecko."
          />
        </Helmet>

        <h1>Crypto Converter</h1>

        <p className="intro-text">
          Welcome to the Crypto Converter! Use this tool to quickly and easily calculate the value of various cryptocurrencies in your chosen fiat currency and vice versa.
        </p>

        <p className="usage-guide">
          Enter the amount you wish to convert and select your preferred cryptocurrency and fiat currency. Use the "⇆" button to switch direction.
        </p>

        <div className="conversion-box">
          <select value={crypto} onChange={(e) => setCrypto(e.target.value)}>
            {Object.keys(cryptoOptions).map((key) => (
              <option key={key} value={key}>
                {cryptoOptions[key]}
              </option>
            ))}
          </select>

          <button onClick={toggleConversion}>⇆</button>

          <select value={fiat} onChange={(e) => setFiat(e.target.value)}>
            {Object.keys(fiatOptions).map((key) => (
              <option key={key} value={key}>
                {fiatOptions[key]}
              </option>
            ))}
          </select>

          <div>
            {reverse ? (
              <>
                <input
                  type="number"
                  value={fiatAmount}
                  onChange={handleFiatAmountChange}
                  placeholder="Amount in fiat"
                />
                <span>{fiat.toUpperCase()}</span>
                <input
                  type="number"
                  value={cryptoAmount}
                  onChange={handleCryptoAmountChange}
                  placeholder="Amount in crypto"
                />
                <span>{cryptoOptions[crypto]}</span>
              </>
            ) : (
              <>
                <input
                  type="number"
                  value={cryptoAmount}
                  onChange={handleCryptoAmountChange}
                  placeholder="Amount in crypto"
                />
                <span>{cryptoOptions[crypto]}</span>
                <input
                  type="number"
                  value={fiatAmount}
                  onChange={handleFiatAmountChange}
                  placeholder="Amount in fiat"
                />
                <span>{fiat.toUpperCase()}</span>
              </>
            )}
          </div>
        </div>

        <div className="rate-info">
          {rate ? (
            <p>
              Current rate: 1 {cryptoOptions[crypto]} = {rate} {fiat.toUpperCase()}
            </p>
          ) : (
            <p>Loading rate...</p>
          )}
        </div>
      </div>

      {/* Footer hozzáadása a fő konténeren kívül */}
      <Footer />
    </>
  );
};

export default CryptoConverter;
