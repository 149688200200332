import React, { useState, useEffect } from 'react';
import './cryptoTicker.css'; // A megfelelő ticker CSS fájl
import axios from 'axios';

function CryptoTicker() {
  const [cryptos, setCryptos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.coincap.io/v2/assets?limit=10');
        const cryptoData = response.data.data;
        setCryptos(cryptoData);
      } catch (error) {
        console.error('Error fetching the crypto data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Frissítés 60 másodpercenként
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="crypto-ticker">
      <div className="ticker-track">
        {cryptos.map((crypto) => (
          <div className="ticker-item" key={crypto.id}>
            <img 
              src={`https://assets.coincap.io/assets/icons/${crypto.symbol.toLowerCase()}@2x.png`} 
              alt={crypto.symbol} 
              className="coin-icon"
            />
            <span>{crypto.symbol}:</span>
            <span>${parseFloat(crypto.priceUsd).toFixed(2)}</span>
            <span className={crypto.changePercent24Hr >= 0 ? 'positive' : 'negative'}>
              {parseFloat(crypto.changePercent24Hr).toFixed(2)}%
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CryptoTicker;
