import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './CategorySidebar.css';
import fetchCategories from './fetchCategories';
import { Helmet } from 'react-helmet';

const CategorySidebar = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isAtFooter, setIsAtFooter] = useState(false); // Figyeljük, hogy elérjük-e a footer-t

  const sidebarRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const loadCategories = async () => {
      const data = await fetchCategories();
      setCategories(data);
    };

    loadCategories();

    // Footer és Sidebar elemek
    footerRef.current = document.querySelector('footer');

    // IntersectionObserver beállítása a footer figyeléséhez
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsAtFooter(entry.isIntersecting); // Beállítjuk, ha a sidebar eléri a footer-t
      },
      { root: null, threshold: 0.1 } // 10%-os átfedést figyelünk
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <aside
      className={`category-sidebar ${isAtFooter ? 'at-footer' : 'fixed'}`}
      ref={sidebarRef}
    >
      <h2>Popular Categories</h2>
      <ul>
        {categories && categories.length > 0 ? (
          categories.map((category) => (
            <li key={category.slug?.current || category.title}>
              <Link
                to={`/category/${category.slug?.current || ''}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category.title}
              </Link>
            </li>
          ))
        ) : (
          <p>No categories found</p>
        )}
      </ul>

      {selectedCategory && (
        <Helmet>
          <title>{selectedCategory.metaTitle || selectedCategory.title}</title>
          <meta name="description" content={selectedCategory.metaDescription || ''} />
          <meta name="keywords" content={selectedCategory.metaKeywords?.join(', ') || ''} />
        </Helmet>
      )}
    </aside>
  );
};

export default CategorySidebar;
