// CryptoNews.js
import React, { useState, useEffect } from 'react';
import sanityClient from './sanityClient';
import './CryptoNews.css';
import { Link, useParams, useLocation } from 'react-router-dom';
import CryptoTicker from './cryptoTicker';
import imageUrlBuilder from '@sanity/image-url';
import MiniArticles from './MiniArticles';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';
import Footer from './Footer';
import CategorySidebar from './CategorySidebar';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const renderExcerpt = (body) => {
  return (
    body
      ?.map((block) => {
        if (block._type === 'block') {
          return block.children
            .map((child) => (child.text ? child.text : ''))
            .join(' ');
        }
        return '';
      })
      .join(' ')
      .split(' ')
      .slice(0, 30)
      .join(' ') + '...'
  );
};

const renderDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function CryptoNews() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const postsPerPage = 10;
  const { slug } = useParams();
  const location = useLocation();
  const canonicalUrl = `https://jsoroko.com${location.pathname}`;

  useEffect(() => {
    const postQuery = slug
      ? `*[_type == "post" && "${slug}" in categories[]->slug.current] | order(publishedAt desc){
           _id,
           title,
           slug,
           body,
           mainImage{
             asset->{
               _id,
               url
             }
           },
           publishedAt,
           categories[]->{title, slug},
           metaTitle,
           metaDescription,
           keywords
         }`
      : `*[_type == "post"] | order(publishedAt desc){
           _id,
           title,
           slug,
           body,
           mainImage{
             asset->{
               _id,
               url
             }
           },
           publishedAt,
           categories[]->{title, slug},
           metaTitle,
           metaDescription,
           keywords
         }`;

    sanityClient
      .fetch(postQuery)
      .then((postData) => {
        const uniquePosts = postData.filter(
          (post, index, self) =>
            index === self.findIndex((t) => t._id === post._id)
        );
        setBlogPosts(uniquePosts);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data from Sanity:', error);
        setIsLoading(false);
      });
  }, [slug]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const filteredPosts = blogPosts.filter(
    (post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      renderExcerpt(post.body).toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const maxPagesToShow = 7;

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredPosts.length / postsPerPage);
    const pages = [];

    let startPage = currentPage <= maxPagesToShow ? 1 : currentPage - maxPagesToShow + 1;
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`page-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => paginate(currentPage + 1)}
          className="page-button"
        >
          Next
        </button>
      );
    }

    return pages;
  };

  if (isLoading) {
    return <p>Loading posts...</p>;
  }

  return (
    <>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />

      <div className={`crypto-news-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <header className="navbar">
          <div className="left-section">
            <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="btn btn-secondary">☰ Networks</button>
          </div>
        </header>

        <CryptoTicker />

        <h1 className="crypto-news-title">Crypto News</h1>

        <Helmet>
          <title>Crypto News - Stay updated on cryptocurrency</title>
          <link rel="canonical" href={canonicalUrl} />
          <meta name="description" content="Stay up to date with the latest cryptocurrency news and insights." />
          <meta name="keywords" content="crypto news, cryptocurrency, blockchain, MemeCoins, digital assets" />
        </Helmet>

        <div className="search-bar">
          <input
            type="text"
            placeholder="Search posts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="main-content">
          <MiniArticles blogPosts={blogPosts} />

          <div className="post-list">
            {currentPosts.length > 0 ? (
              currentPosts.map((post, index) => (
                <div key={index} className="blog-post">
                  {post.mainImage && post.mainImage.asset && post.mainImage.asset.url && (
                    <img
                      src={urlFor(post.mainImage).width(610).height(300).url()}
                      alt={post.title || 'Post image'}
                      className="post-main-image"
                      style={{
                        width: '100%',
                        height: '300px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        marginBottom: '15px'
                      }}
                    />
                  )}
                  <h2 className="post-title">{post.title || 'Untitled post'}</h2>
                  <p className="post-date">Published on: {renderDate(post.publishedAt)}</p>
                  <p className="post-excerpt">{renderExcerpt(post.body)}</p>
                  <Link to={`/post/${post.slug.current}`} className="read-more">
                    Read More
                  </Link>
                </div>
              ))
            ) : (
              <p>No posts found</p>
            )}
          </div>

          <CategorySidebar />
        </div>

        <div className="pagination">
          {renderPagination()}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default CryptoNews;
