// Footer.js
import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

// Az ikonok importálása
import youtubeIcon from './icons/youtube4.png';
import twitterIcon from './icons/twitter.png';
import tiktokIcon from './icons/tiktok.png';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h3>News</h3>
        <Link to="/category/bitcoin" onClick={scrollToTop}>Bitcoin</Link>
        <Link to="/category/blockchain" onClick={scrollToTop}>Blockchain</Link>
        <Link to="/category/ethereum" onClick={scrollToTop}>Ethereum</Link>
        <Link to="/category/altcoins" onClick={scrollToTop}>Altcoins</Link>
        <Link to="/category/policy" onClick={scrollToTop}>Policy & Regulations</Link>
        <Link to="/category/ai" onClick={scrollToTop}>AI</Link>
        <Link to="/category/nft" onClick={scrollToTop}>NFT</Link>
        <Link to="/category/defi" onClick={scrollToTop}>DeFi</Link>
      </div>

      <div className="footer-column">
        <h3>Navigate</h3>
        <Link to="/" onClick={scrollToTop}>Home</Link>
        <Link to="/base-network" onClick={scrollToTop}>Base Network</Link>
        <Link to="/add-features" onClick={scrollToTop}>Add Features</Link>
        <Link to="/library" onClick={scrollToTop}>Library</Link>
        <Link to="/calculator" onClick={scrollToTop}>Calculator</Link>
        <Link to="/crypto-news" onClick={scrollToTop}>Crypto News</Link>
        <Link to="/Learn" onClick={scrollToTop}>Learn and Guide</Link>
        <a href="https://coin360.com/?utm_source=cointelegraph&utm_medium=referral&utm_campaign=menu&utm_term=header" target="_blank" rel="noopener noreferrer">
          Heatmap
        </a>
      </div>

      <div className="footer-column">
        <h3>Follow Us</h3>
        <a href="https://www.youtube.com/@Creatememecoins/videos" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={youtubeIcon} alt="YouTube" className="social-icon" />
          YouTube
        </a>
        <a href="https://x.com/creatememecoins" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={twitterIcon} alt="Twitter" className="social-icon" />
          Twitter
        </a>
        <a href="https://www.tiktok.com/@creatememecoins?lang=en" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={tiktokIcon} alt="TikTok" className="social-icon" />
          TikTok
        </a>
      </div>
    </footer>
  );
};

export default Footer;
