// CryptoCalculatorPage.js
import React from 'react';
import CryptoCalculator from './CryptoCalculator';
import './CryptoCalculator.css';

const CryptoCalculatorPage = () => {
  return (
    <div className="calculator-page-container">
      <CryptoCalculator />
    </div>
  );
};

export default CryptoCalculatorPage;
