import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Nodes.module.css';

const nodesData = [
    {
        name: "Reth",
        description: "Reth is a high-performance Ethereum node, optimized for resource efficiency and seamless network participation.",
        link: "reth",
        img: "/images/reth.png"
    },
    {
        name: "Geth",
        description: "Geth is a widely used Ethereum node implementation in Go, providing a secure and efficient way to interact with the Ethereum blockchain.",
        link: "geth",
        img: "/images/geth.png"
    },
    {
        name: "Nethermind",
        description: "Nethermind is a high-performance, highly configurable Ethereum execution client built on .NET that runs on Linux, Windows, and macOS, supporting Clique, Aura, and Ethash consensus mechanisms.",
        link: "nethermind",
        img: "/images/Nethermind.png"
    },
    {
        name: "Erigon",
        description: "Erigon is an Ethereum execution client designed for high efficiency and modularity, offering rapid synchronization and optimized resource management for blockchain networks.",
        link: "erigon",
        img: "/images/erigon.png"
    },
    {
        name: "Prysm",
        description: "Prysm is an Ethereum consensus client implemented in Go, enabling users to run a beacon node and validator client to participate in Ethereum's proof-of-stake consensus mechanism.",
        link: "prysm",
        img: "/images/prysm.png"
    },
    // További node-ok itt
];

function Nodes() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>Blockchain Nodes | Essential Tools for Blockchain Networks</title>
                <meta name="description" content="Explore various blockchain nodes essential for maintaining and interacting with decentralized networks." />
                <meta name="keywords" content="blockchain nodes, Reth, Geth, Ethereum, blockchain infrastructure, decentralized networks" />
                <link rel="canonical" href="https://www.jsoroko.com/library/nodes" />
            </Helmet>

            <header>
                <h1>Blockchain Nodes</h1>
                <p>Explore essential blockchain nodes that support decentralized networks, including Geth, Reth, Prysm, and Erigon. Each node type contributes to the Ethereum ecosystem, offering unique benefits for security, data accuracy, and efficient transaction processing. Whether for developers or enthusiasts, learn about the nodes that keep the blockchain running smoothly.</p>
            </header>

            <section className={styles.nodesList}>
                {nodesData.map((node, index) => (
                    <Link to={`/library/nodes/${node.link}`} key={index} className={styles.nodeCard}>
                        <img src={node.img} alt={`${node.name} Logo`} className={styles.nodeImage} loading="lazy" />
                        <h2>{node.name}</h2>
                        <p>{node.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default Nodes;