import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './ApiDetailPage.module.css';

const apiData = [
    { 
        title: "Binance API", 
        description: "Comprehensive trading and market data from Binance exchange.", 
        category: "exchange", 
        link: "binance", 
        img: "/images/binance.png",
        details: `
           <p>The Binance API (Application Programming Interface) allows developers and traders to access and automate services on the Binance cryptocurrency exchange. Through the API, users can conduct trading operations programmatically, retrieve market data, and manage their accounts.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Automated Trading:</strong> The API enables automated trading for spot, margin, futures, and options with over 300 digital and fiat currencies.</li>
               <li><strong>Market Data Access:</strong> Users can access real-time market data, including prices, trading volumes, and order book information.</li>
               <li><strong>Account Management:</strong> Through the API, users can manage their accounts, check balances, create and cancel orders, and track transactions.</li>
               <li><strong>WebSocket Services:</strong> Binance API also offers WebSocket services that provide real-time data streams for market events and trading activities.</li>
           </ul>
           <p>This API is particularly useful for those engaging in algorithmic trading, developing trading bots, or building their own trading platforms. The Binance API documentation provides detailed guidance on how to use various endpoints and functions.</p>
           <p>It's important to note that creating unique API keys and applying appropriate security settings is essential to protect your account. Binance recommends setting IP restrictions on API keys to enhance security.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.binance.com/en/binance-api" target="_blank" rel="noopener noreferrer">Binance’s official API page</a>.</p>
        `,
        meta: {
            title: "Binance API | Cryptocurrency Trading and Market Data",
            description: "Access the Binance API for comprehensive cryptocurrency trading, market data, and automated trading tools.",
            keywords: "Binance API, cryptocurrency API, trading API, market data, Binance exchange, algorithmic trading"
        }
    },
    { 
        title: "Coinbase API", 
        description: "Access Coinbase exchange data and trading functionality.", 
        category: "exchange", 
        link: "coinbase", 
        img: "/images/coinbase.png",
        details: `
<p>The Coinbase API (Application Programming Interface) allows developers and traders to access various features and data on the Coinbase cryptocurrency platform. Through the API, users can perform a range of operations, including automated trading, account management, and accessing real-time market data.</p>

<h2>Main Features</h2>
<ul>
    <li><strong>Automated Trading:</strong> The API enables users to programmatically buy and sell cryptocurrencies, making it suitable for creating trading bots and implementing automated trading strategies.</li>
    <li><strong>Market Data Access:</strong> The Coinbase API provides access to real-time market data, including prices, trading volumes, and historical trade data.</li>
    <li><strong>Account Management:</strong> With the API, users can manage their Coinbase accounts, check balances, track transactions, and convert cryptocurrencies.</li>
    <li><strong>Billing and Payments:</strong> The Coinbase API supports billing and payment functions, allowing online stores and other business applications to integrate cryptocurrency-based payments.</li>
    <li><strong>WebSocket Support:</strong> The Coinbase API provides real-time data through WebSocket, enabling users to receive continuous updates on market changes and trading activities.</li>
</ul>

<p>This API is especially useful for those engaging in algorithmic trading, developing trading bots, or integrating cryptocurrency payments into their own applications. The API documentation provides detailed guidance on how to use the available endpoints and functions.</p>

<p>It's important to note that unique API keys are required to use the API, and implementing security settings like IP restrictions is recommended to protect your account.</p>

<p>For more information and detailed documentation, visit <a href="https://www.coinbase.com/developer-platform/products/exchange-api" target="_blank" rel="noopener noreferrer">Coinbase official API page</a>.</p>

        `,
        meta: {
            title: "Coinbase API | Access Coinbase Market and Trading Data",
            description: "Use the Coinbase API to access market data, manage accounts, and process cryptocurrency payments on Coinbase.",
            keywords: "Coinbase API, cryptocurrency API, trading API, Coinbase exchange, cryptocurrency payments"
        }
    },
    { 
        title: "Crypto.com API", 
        description: "Access Crypto.com's trading and financial data for seamless integration with crypto markets.", 
        category: "exchange", 
        link: "crypto-com", 
        img: "/images/crypto-com.png", // A kép elérési útja a public/images mappában
        details: `
<p>The Crypto.com API (Application Programming Interface) provides developers and traders with access to a range of services on the Crypto.com platform. Through this API, users can perform trading operations programmatically, access market data, and manage their accounts on Crypto.com.</p>

<h2>Main Features</h2>
<ul>
    <li><strong>Automated Trading:</strong> The API allows users to conduct automated trading for a variety of cryptocurrencies, supporting different trading strategies and order types.</li>
    <li><strong>Market Data Access:</strong> Users can access real-time market data, including current prices, trading volumes, order book details, and historical market data.</li>
    <li><strong>Account Management:</strong> With the API, users can manage their Crypto.com accounts, check balances, view transaction histories, and execute trades.</li>
    <li><strong>Billing and Payments:</strong> The Crypto.com API offers payment solutions, allowing businesses to integrate cryptocurrency payment options into their platforms.</li>
    <li><strong>WebSocket Support:</strong> Crypto.com provides WebSocket services for real-time updates on market movements, order statuses, and other trading activities.</li>
</ul>

<p>This API is particularly useful for developers creating trading bots, integrating cryptocurrency payments into applications, or engaging in algorithmic trading. The Crypto.com API documentation provides detailed information on how to utilize each endpoint and available functionality.</p>

<p>It's important to create unique API keys and apply recommended security settings, such as IP whitelisting, to protect your account.</p>

<p>For more information and detailed documentation, visit <a href="https://crypto.com/exchange/api" target="_blank" rel="noopener noreferrer">Crypto.com official API page</a>.</p>

        `,
        meta: {
            title: "Crypto.com API | Automated Trading & Market Data Access",
            description: "Integrate with Crypto.com's API to access trading, market data, account management, and payment solutions. Ideal for developers creating bots or trading applications.",
            keywords: "Crypto.com API, cryptocurrency trading API, market data, automated trading, account management, payment solutions, WebSocket, crypto API"
        }
    },
    { 
        title: "Bybit API", 
        description: "Utilize Bybit's comprehensive trading tools and real-time market data for efficient integration with cryptocurrency trading environments.",
        category: "exchange",
        link: "bybit",
        img: "/images/bybit.png", // Path to the image in the public/images folder
        details: `
<p>The Bybit API (Application Programming Interface) allows developers and traders to access and automate services on the Bybit cryptocurrency exchange. Through this API, users can conduct trading operations programmatically, retrieve market data, and manage their accounts.</p>

<h2>Main Features</h2>
<ul>
    <li><strong>Automated Trading:</strong> The API supports spot, margin, USDT perpetual, USDC perpetual, USDC futures, inverse futures, and options trading, allowing users to create various order types, including limit, market, and conditional orders.</li>
    <li><strong>Market Data Access:</strong> Users can access real-time market data, including prices, trading volumes, and order book information.</li>
    <li><strong>Account Management:</strong> The API allows users to manage their accounts, check balances, track transactions, and handle open positions.</li>
    <li><strong>WebSocket Support:</strong> Bybit API offers WebSocket services that provide real-time data streams for market events and trading activities, allowing users to receive instant updates.</li>
</ul>

<p>This API is particularly useful for those engaging in algorithmic trading, developing trading bots, or building custom trading platforms. The Bybit API documentation provides detailed guidance on how to use the various endpoints and functions.</p>

<p>It's important to note that unique API keys are required to use the API, and implementing security settings like IP restrictions is recommended to protect your account.</p>

<p>For more information and detailed documentation, visit <a href="https://www.bybit.com/future-activity/en/developer" target="_blank" rel="noopener noreferrer">Bybit official API page</a>.</p>

        `,
        meta: {
            title: "Bybit API | Automated Trading & Real-Time Market Data Access",
            description: "Integrate with Bybit's API to access automated trading, real-time market data, account management, and risk management solutions. Ideal for developers building bots or custom trading platforms.",
            keywords: "Bybit API, cryptocurrency trading API, real-time market data, automated trading, account management, risk management, WebSocket, crypto API"
        }
    },
    { 
        title: "Infura API", 
        description: "Access Ethereum and IPFS networks with Infura's reliable, secure API.", 
        category: "node", 
        link: "infura", 
        img: "/images/infura.png",
        details: `
           <p>The Infura API provides developers with easy access to the Ethereum blockchain and IPFS network. With Infura, users can interact with smart contracts, send transactions, and retrieve blockchain data without running a full Ethereum node.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Ethereum Blockchain Access:</strong> Provides reliable, secure access to Ethereum's mainnet and testnets for querying blockchain data and sending transactions.</li>
               <li><strong>IPFS Integration:</strong> Allows storage and retrieval of data on the InterPlanetary File System (IPFS), supporting decentralized applications.</li>
               <li><strong>Smart Contract Interaction:</strong> Enables seamless interaction with smart contracts deployed on the Ethereum blockchain.</li>
               <li><strong>WebSocket and HTTP Endpoints:</strong> Supports both WebSocket and HTTP endpoints for real-time updates and efficient data queries.</li>
           </ul>
           <p>Infura is ideal for developers building dApps, interacting with smart contracts, and leveraging Ethereum's decentralized network without needing to manage nodes.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.infura.io/" target="_blank" rel="noopener noreferrer">Infura’s official API page</a>.</p>
        `,
        meta: {
            title: "Infura API | Ethereum and IPFS Network Access",
            description: "Utilize the Infura API to access Ethereum and IPFS for decentralized applications and blockchain data.",
            keywords: "Infura API, Ethereum API, blockchain API, IPFS API, smart contracts, decentralized applications"
        }
    },
    {
        title: "Alchemy API",
        description: "Powerful blockchain development platform and Ethereum API.",
        category: "node",
        link: "alchemy",
        img: "/images/alchemy.png",
        details: `
           <p>The Alchemy API is a comprehensive platform for building and scaling decentralized applications on Ethereum and other blockchains. It provides robust tools and infrastructure for developers to interact with blockchain data, manage transactions, and monitor performance.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Enhanced API Endpoints:</strong> Offers advanced, optimized endpoints for reading and writing data to the blockchain, making it easier for developers to access and manage blockchain data.</li>
               <li><strong>Real-Time Notifications:</strong> Provides Webhooks for real-time notifications about transactions, blocks, and smart contract events.</li>
               <li><strong>Analytics and Monitoring:</strong> Built-in analytics tools allow developers to monitor performance, usage, and error rates for their applications.</li>
               <li><strong>Multi-Chain Support:</strong> Supports Ethereum, Polygon, and other major blockchains, allowing developers to build cross-chain applications.</li>
           </ul>
           <p>Alchemy’s API is designed to simplify blockchain development and make decentralized applications more scalable and reliable.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.alchemy.com/" target="_blank" rel="noopener noreferrer">Alchemy’s official API page</a>.</p>
        `,
        meta: {
            title: "Alchemy API | Blockchain Development and Ethereum API",
            description: "Use Alchemy API for advanced blockchain development, real-time notifications, and analytics for Ethereum and other networks.",
            keywords: "Alchemy API, Ethereum API, blockchain development, dApp development, real-time notifications, blockchain analytics"
        }
    },
    {
        title: "GetBlock API",
        description: "Fast and easy access to blockchain nodes for developers.",
        category: "node",
        link: "getblock",
        img: "/images/getblock.png",
        details: `
           <p>The GetBlock API provides developers with easy and fast access to full nodes of various blockchain networks. It supports multiple blockchains and offers JSON-RPC, REST, and WebSocket protocols for seamless integration.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Blockchain Support:</strong> Access full nodes of Ethereum, Bitcoin, Binance Smart Chain, and many other popular blockchains.</li>
               <li><strong>JSON-RPC, REST, and WebSocket:</strong> Choose from multiple protocol options for flexible and scalable API integration.</li>
               <li><strong>Instant Node Access:</strong> Connect to full nodes without the need to run and maintain your own blockchain infrastructure.</li>
               <li><strong>High Throughput:</strong> Optimized for high request rates, making it suitable for data-heavy applications.</li>
           </ul>
           <p>GetBlock simplifies blockchain development by providing fast, reliable access to blockchain networks for dApp developers.</p>
           <p>For more information and detailed documentation, visit <a href="https://getblock.io/" target="_blank" rel="noopener noreferrer">GetBlock’s official API page</a>.</p>
        `,
        meta: {
            title: "GetBlock API | Access Blockchain Nodes Instantly",
            description: "Use GetBlock API for fast access to full blockchain nodes across multiple networks with JSON-RPC, REST, and WebSocket protocols.",
            keywords: "GetBlock API, blockchain nodes, Ethereum API, Bitcoin API, Binance Smart Chain API, JSON-RPC, WebSocket, REST API"
        }
    },
    {
        title: "QuickNode API",
        description: "Accelerate dApp development with high-performance blockchain infrastructure.",
        category: "node",
        link: "quicknode",
        img: "/images/quicknode.png",
        details: `
           <p>The QuickNode API offers fast, reliable, and scalable access to blockchain nodes, supporting multiple blockchains. It’s designed to help developers build, deploy, and scale decentralized applications with ease.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Chain Support:</strong> Provides access to Ethereum, Bitcoin, Solana, Polygon, and other major blockchain networks.</li>
               <li><strong>Global Infrastructure:</strong> Utilizes a global network of nodes to deliver low-latency, high-speed blockchain data.</li>
               <li><strong>Enhanced API Features:</strong> Offers advanced features like NFT API, transaction decoding, and event indexing for enhanced dApp functionality.</li>
               <li><strong>Developer Tools:</strong> Includes monitoring, analytics, and debugging tools to optimize dApp performance.</li>
           </ul>
           <p>QuickNode empowers developers with the tools and infrastructure needed to scale dApps seamlessly across multiple blockchains.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.quicknode.com/" target="_blank" rel="noopener noreferrer">QuickNode’s official API page</a>.</p>
        `,
        meta: {
            title: "QuickNode API | High-Performance Blockchain Infrastructure",
            description: "Utilize QuickNode API for fast, reliable access to blockchain networks, including Ethereum, Solana, and Polygon, with developer-friendly features.",
            keywords: "QuickNode API, blockchain API, Ethereum API, Solana API, dApp infrastructure, NFT API, decentralized applications"
        }
    },
    {
        title: "Chainstack API",
        description: "Enterprise-grade blockchain infrastructure for multiple protocols.",
        category: "node",
        link: "chainstack",
        img: "/images/chainstack.png",
        details: `
           <p>The Chainstack API provides developers with robust, scalable, and enterprise-grade access to multiple blockchain networks. Chainstack makes it easy to deploy and manage nodes on various blockchain protocols, enabling developers to focus on building dApps.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Protocol Support:</strong> Supports Ethereum, Polygon, Binance Smart Chain, and more, enabling flexibility for multi-chain applications.</li>
               <li><strong>Dedicated Nodes and Shared Nodes:</strong> Choose between dedicated infrastructure for high performance or shared nodes for cost efficiency.</li>
               <li><strong>Advanced Security:</strong> Provides built-in security measures, including DDoS protection and IP whitelisting.</li>
               <li><strong>Easy Node Management:</strong> Includes tools for deploying, scaling, and monitoring nodes with minimal hassle.</li>
           </ul>
           <p>Chainstack simplifies blockchain infrastructure management, allowing developers to focus on innovation rather than maintenance.</p>
           <p>For more information and detailed documentation, visit <a href="https://chainstack.com/" target="_blank" rel="noopener noreferrer">Chainstack’s official API page</a>.</p>
        `,
        meta: {
            title: "Chainstack API | Enterprise-Grade Blockchain Infrastructure",
            description: "Leverage Chainstack API for scalable, secure access to Ethereum, Binance Smart Chain, and other blockchain protocols.",
            keywords: "Chainstack API, blockchain infrastructure, Ethereum API, Binance Smart Chain, enterprise blockchain, node management"
        }
    },
    {
        title: "Moralis API",
        description: "Comprehensive Web3 development platform with multi-chain support.",
        category: "node",
        link: "moralis",
        img: "/images/moralis.png",
        details: `
           <p>The Moralis API provides a powerful and easy-to-use platform for Web3 development, offering multi-chain support for building dApps. It simplifies backend operations, allowing developers to focus on frontend and dApp logic.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Chain Support:</strong> Compatible with Ethereum, Base Network, Polygon, and other major blockchains.</li>
               <li><strong>Authentication and User Management:</strong> Built-in Web3 authentication methods, including MetaMask, WalletConnect, and others.</li>
               <li><strong>Real-Time Database:</strong> Automatic syncing of on-chain data to a real-time database for easy querying.</li>
               <li><strong>NFT and Token API:</strong> Specialized endpoints for handling NFTs, token balances, and transfers across supported chains.</li>
           </ul>
           <p>Moralis is ideal for developers looking to build full-stack Web3 applications with minimal backend infrastructure.</p>
           <p>For more information and detailed documentation, visit <a href="https://developers.moralis.com/" target="_blank" rel="noopener noreferrer">Moralis’s official API page</a>.</p>
        `,
        meta: {
            title: "Moralis API | Web3 Development Platform",
            description: "Utilize Moralis API for seamless Web3 development, multi-chain support, user management, and real-time blockchain data.",
            keywords: "Moralis API, Web3 API, blockchain API, dApp development, NFT API, Base Network, user authentication"
        }
    },
    {
        title: "LlamaNodes API",
        description: "Reliable and scalable blockchain node infrastructure for Web3 developers.",
        category: "node",
        link: "llamanodes",
        img: "/images/llamanodes.png",
        details: `
           <p>The LlamaNodes API provides a robust and scalable node infrastructure service, supporting multiple blockchains for Web3 development. It enables developers to quickly access full nodes without the need to manage their own infrastructure.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Blockchain Support:</strong> Offers nodes for Ethereum, Polygon, Binance Smart Chain, and other major blockchain networks.</li>
               <li><strong>High Availability and Reliability:</strong> Optimized for low latency and high uptime, ensuring reliable access to blockchain data.</li>
               <li><strong>Flexible API Access:</strong> Supports JSON-RPC, REST, and WebSocket protocols for various development needs.</li>
               <li><strong>Scalable Infrastructure:</strong> Allows developers to scale their applications seamlessly with reliable node access.</li>
           </ul>
           <p>LlamaNodes is designed to simplify blockchain development by providing reliable, high-performance node infrastructure.</p>
           <p>For more information and detailed documentation, visit <a href="https://llamanodes.com/" target="_blank" rel="noopener noreferrer">LlamaNodes’s official API page</a>.</p>
        `,
        meta: {
            title: "LlamaNodes API | Scalable Blockchain Node Infrastructure",
            description: "Leverage LlamaNodes API for reliable and scalable blockchain node access for Ethereum, Polygon, BSC, and more.",
            keywords: "LlamaNodes API, blockchain nodes, Ethereum API, Polygon API, Binance Smart Chain, Web3 infrastructure, JSON-RPC, WebSocket"
        }
    },
    {
        title: "Ankr API",
        description: "Decentralized node infrastructure and multi-chain API for Web3 developers.",
        category: "node",
        link: "ankr",
        img: "/images/ankr.png",
        details: `
           <p>The Ankr API offers decentralized, high-performance blockchain infrastructure, supporting multiple blockchains and designed for Web3 and DeFi applications. It enables developers to interact with blockchain networks efficiently without managing their own nodes.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Decentralized Infrastructure:</strong> Provides a decentralized node network for enhanced security and reliability.</li>
               <li><strong>Multi-Chain Access:</strong> Supports Ethereum, Binance Smart Chain, Polygon, and other popular blockchains for cross-chain dApp development.</li>
               <li><strong>Web3 and DeFi Optimized:</strong> Tailored features for Web3 applications, including staking, token transfers, and DeFi protocols.</li>
               <li><strong>Developer Tools:</strong> Offers RPC endpoints, WebSocket, and REST API support for seamless integration.</li>
           </ul>
           <p>Ankr's infrastructure is built to empower developers with decentralized, scalable access to blockchain networks.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.ankr.com/" target="_blank" rel="noopener noreferrer">Ankr’s official API page</a>.</p>
        `,
        meta: {
            title: "Ankr API | Decentralized Multi-Chain Blockchain Infrastructure",
            description: "Use Ankr API for decentralized, multi-chain access to blockchain networks with support for Web3 and DeFi applications.",
            keywords: "Ankr API, decentralized nodes, blockchain infrastructure, Web3 API, DeFi, Ethereum, Binance Smart Chain, multi-chain"
        }
    },
    {
        title: "Helius API",
        description: "Developer-friendly API for Solana blockchain data and analytics.",
        category: "node",
        link: "helius",
        img: "/images/helius.png",
        details: `
           <p>The Helius API is designed for developers building on the Solana blockchain, offering simplified access to blockchain data, analytics, and enhanced indexing capabilities. It provides powerful tools for tracking, querying, and analyzing Solana-based transactions and events.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Solana Data Access:</strong> Direct access to Solana blockchain data, including transactions, token balances, and account information.</li>
               <li><strong>Enhanced Indexing:</strong> Advanced indexing capabilities for efficient data retrieval and tracking specific transactions or events.</li>
               <li><strong>Real-Time Notifications:</strong> Webhook support for real-time alerts on specific events and transactions.</li>
               <li><strong>Analytics Tools:</strong> Built-in analytics for transaction tracking, performance metrics, and historical data analysis.</li>
           </ul>
           <p>Helius provides a comprehensive API for developers building on Solana, offering streamlined data access and analytics for scalable applications.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.helius.dev/" target="_blank" rel="noopener noreferrer">Helius’s official API page</a>.</p>
        `,
        meta: {
            title: "Helius API | Solana Blockchain Data and Analytics",
            description: "Utilize the Helius API for advanced Solana blockchain data, real-time notifications, and enhanced analytics tools.",
            keywords: "Helius API, Solana API, blockchain data, Solana analytics, transaction tracking, Web3 development, real-time notifications"
        }
    },
    {
        title: "Shyft API",
        description: "Reliable and optimized RPC API for Solana developers, designed for high uptime and speed.",
        category: "node",
        link: "shyft",
        img: "/images/shyft.png",
        details: `
           <p>The Shyft API is a robust RPC service designed for developers building on the Solana blockchain. It offers reliable, fast access to Solana’s data and transactions, optimized for performance and availability.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>High Uptime:</strong> Shyft ensures high availability for uninterrupted access to Solana blockchain data.</li>
               <li><strong>Performance Optimized:</strong> Fast response times for efficient transaction handling and data queries.</li>
               <li><strong>Developer-Friendly:</strong> Streamlined setup and comprehensive documentation for easy integration into Solana-based projects.</li>
               <li><strong>Scalability:</strong> Designed to handle growing application demands with scalable infrastructure.</li>
           </ul>
           <p>Shyft offers a reliable, high-performance RPC API for Solana developers, providing the necessary infrastructure to build scalable blockchain applications.</p>
           <p>For more information and detailed documentation, visit <a href="https://shyft.to" target="_blank" rel="noopener noreferrer">Shyft’s official API page</a>.</p>
        `,
        meta: {
            title: "Shyft API | Optimized RPC Service for Solana",
            description: "Access the Shyft API for high-performance, reliable RPC services on the Solana blockchain with excellent uptime and speed.",
            keywords: "Shyft API, Solana RPC, blockchain API, Solana data, transaction handling, Web3 development, scalable RPC services"
        }
    },
    {
        title: "Vezgo API",
        description: "Comprehensive API for accessing digital asset data and Web3 accounts across wallets and exchanges.",
        category: "wallet",
        link: "vezgo",
        img: "/images/vezgo.png",
        details: `
           <p>The Vezgo API provides seamless access to a wide range of digital assets and Web3 accounts, enabling developers to track and manage portfolios across multiple wallets and exchanges.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Wallet Support:</strong> Easily access data from various wallets and exchanges within a single API.</li>
               <li><strong>Real-Time Portfolio Tracking:</strong> Track asset balances, transaction histories, and portfolio values in real-time.</li>
               <li><strong>Cross-Platform Compatibility:</strong> Integrate data from multiple blockchain networks and platforms.</li>
               <li><strong>Developer-Friendly:</strong> Simple integration process with detailed documentation for easy adoption.</li>
           </ul>
           <p>Vezgo offers a powerful API for developers needing comprehensive portfolio and account management solutions in the Web3 space.</p>
           <p>For more information and detailed documentation, visit <a href="https://vezgo.com" target="_blank" rel="noopener noreferrer">Vezgo’s official API page</a>.</p>
        `,
        meta: {
            title: "Vezgo API | Digital Asset and Web3 Account Data",
            description: "Utilize the Vezgo API to access real-time digital asset and portfolio data across various wallets and exchanges.",
            keywords: "Vezgo API, wallet API, digital assets, portfolio tracking, Web3 data, wallet integration, multi-platform support"
        }
    },
    {
        title: "Moralis Wallet API",
        description: "Developer-friendly API for integrating Web3 wallet functionalities across multiple blockchains.",
        category: "wallet",
        link: "moralis-wallet-api",
        img: "/images/moralis.png",
        details: `
           <p>The Moralis Wallet API is a powerful tool that enables developers to easily integrate cryptocurrency wallet functionalities into their applications, supporting networks like Ethereum, Polygon, and BNB Chain.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Native Balance Retrieval:</strong> Access current and historical native balances of wallets.</li>
               <li><strong>NFT Management:</strong> Query all NFTs and collections in wallets, including metadata and optimized images.</li>
               <li><strong>ERC20 Token Management:</strong> Retrieve current ERC20 token balances and real-time prices, as well as complete historical data at any block number.</li>
               <li><strong>Transaction Tracking:</strong> View all transactions for a wallet, including decoded data and categorized labels.</li>
               <li><strong>Real-Time Notifications:</strong> Webhook support for real-time alerts on specific events and transactions.</li>
           </ul>
           <p>Moralis Wallet API offers a comprehensive solution for developers seeking robust and scalable wallet functionalities for Web3 applications.</p>
           <p>For more information and detailed documentation, visit <a href="https://developers.moralis.com/api/wallet/" target="_blank" rel="noopener noreferrer">Moralis’s official API page</a>.</p>
        `,
        meta: {
            title: "Moralis Wallet API | Integrate Web3 Wallet Functionalities",
            description: "Use the Moralis Wallet API to integrate advanced Web3 wallet functionalities across multiple blockchains, including Ethereum, Polygon, and BNB Chain.",
            keywords: "Moralis Wallet API, Web3 API, cryptocurrency wallet, blockchain integration, Ethereum, Polygon, BNB Chain, NFT management, ERC20 tokens, transaction tracking"
        }
    },
    {
        title: "Coinbase Wallet SDK",
        description: "Open-source SDK enabling seamless integration of dApps with Coinbase Wallet across multiple platforms.",
        category: "wallet",
        link: "coinbase-wallet-sdk",
        img: "/images/coinbase.png",
        details: `
           <p>The Coinbase Wallet SDK is an open-source software development kit that allows developers to connect their decentralized applications (dApps) to Coinbase Wallet users across various platforms, including web and mobile.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Platform Support:</strong> Enables dApp integration with Coinbase Wallet on iOS, Android, and desktop browsers.</li>
               <li><strong>Multi-Chain Compatibility:</strong> Supports Solana and all EVM-compatible chains, such as Ethereum, Polygon, and BNB Chain.</li>
               <li><strong>Easy Integration:</strong> Connect your dApp to Coinbase Wallet with just a few lines of code, facilitating quick and efficient integration.</li>
               <li><strong>Developer-Friendly:</strong> Comprehensive documentation and resources to assist developers throughout the integration process.</li>
               <li><strong>Enhanced Security:</strong> Leverages Coinbase's industry-leading security protocols to ensure safe transactions and data handling.</li>
           </ul>
           <p>The Coinbase Wallet SDK provides a robust solution for developers aiming to integrate their dApps with Coinbase Wallet, offering extensive support and resources to streamline the process.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.coinbase.com/developer-platform/products/wallet-sdk" target="_blank" rel="noopener noreferrer">Coinbase’s official Wallet SDK page</a>.</p>
        `,
        meta: {
            title: "Coinbase Wallet SDK | Seamless dApp Integration",
            description: "Utilize the Coinbase Wallet SDK to integrate your dApp with Coinbase Wallet across multiple platforms, supporting various blockchain networks.",
            keywords: "Coinbase Wallet SDK, dApp integration, cryptocurrency wallet, blockchain development, multi-platform support, Solana, Ethereum, Polygon, BNB Chain"
        }
    },
    {
        title: "Crypto APIs Wallet as a Service",
        description: "Secure and scalable Wallet-as-a-Service (WaaS) API for cryptocurrency applications.",
        category: "wallet",
        link: "cryptoapis-wallet-api",
        img: "/images/cryptoapis.png",
        details: `
           <p>Crypto APIs Wallet as a Service (WaaS) offers a secure and robust solution for integrating cryptocurrency wallet functionalities into applications. It provides a reliable way to manage digital assets with support for multiple blockchains.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Signature Support:</strong> Offers MPC (Multi-Party Computation) wallets for added security, with multi-signature capabilities and user-defined access controls.</li>
               <li><strong>Blockchain Support:</strong> Supports multiple blockchains, allowing users to manage diverse digital assets from a single interface.</li>
               <li><strong>Transaction Management:</strong> Streamlined transaction processing with options for automated fee calculation and transaction broadcasting.</li>
               <li><strong>Balance and Asset Tracking:</strong> Accurate, real-time balance tracking across multiple wallets, including historical data for detailed analysis.</li>
               <li><strong>Enhanced Security:</strong> Provides high-level security protocols, including multi-layer encryption and role-based permissions.</li>
           </ul>
           <p>The Crypto APIs Wallet as a Service is an ideal solution for developers needing secure, scalable wallet management tools within their cryptocurrency applications.</p>
           <p>For more information and detailed documentation, visit <a href="https://cryptoapis.io/" target="_blank" rel="noopener noreferrer">Crypto APIs’ official Wallet as a Service page</a>.</p>
        `,
        meta: {
            title: "Crypto APIs Wallet as a Service | Secure Cryptocurrency Wallet Integration",
            description: "Use Crypto APIs Wallet as a Service to integrate secure, scalable wallet functionalities into your cryptocurrency applications, with multi-signature support and advanced security.",
            keywords: "Crypto APIs, Wallet as a Service, cryptocurrency wallet, digital asset management, multi-signature wallet, blockchain wallet API, secure wallet integration"
        }
    },
    {
        title: "Coinbase Commerce API",
        description: "Enable cryptocurrency payments for online businesses through a secure, non-custodial platform.",
        category: "payment",
        link: "coinbase-commerce-api",
        img: "/images/coinbase.png",
        details: `
           <p>Coinbase Commerce provides a powerful API for businesses to accept cryptocurrency payments directly into their wallets, supporting Bitcoin, Ethereum, Litecoin, and other popular cryptocurrencies.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Non-Custodial Payments:</strong> Funds are directly sent to the merchant’s wallet, without intermediaries or custodial risks.</li>
               <li><strong>Multi-Crypto Support:</strong> Accepts multiple cryptocurrencies, including Bitcoin, Ethereum, Litecoin, and USD Coin.</li>
               <li><strong>Easy Integration:</strong> Simple API setup allows for quick integration into websites and applications.</li>
               <li><strong>Real-Time Notifications:</strong> Webhook support for instant updates on payments and transactions.</li>
               <li><strong>Enhanced Security:</strong> Provides high-level security protocols, ensuring safe and private transactions for users and merchants.</li>
           </ul>
           <p>Coinbase Commerce is an ideal solution for businesses looking to expand into cryptocurrency payments without custodial involvement.</p>
           <p>For more information and detailed documentation, visit <a href="https://www.coinbase.com/commerce/" target="_blank" rel="noopener noreferrer">Coinbase Commerce’s official page</a>.</p>
        `,
        meta: {
            title: "Coinbase Commerce API | Cryptocurrency Payment Integration",
            description: "Enable cryptocurrency payments with Coinbase Commerce API, supporting Bitcoin, Ethereum, and more, with non-custodial security.",
            keywords: "Coinbase Commerce API, cryptocurrency payment gateway, Bitcoin payments, Ethereum payments, non-custodial payments, crypto integration"
        }
    },
    {
        title: "BitPay API",
        description: "A comprehensive API for integrating cryptocurrency payments into e-commerce platforms.",
        category: "payment",
        link: "bitpay-api",
        img: "/images/bitpay.png",
        details: `
           <p>The BitPay API allows businesses to accept cryptocurrency payments directly on their websites or applications, with support for Bitcoin, Bitcoin Cash, and other popular cryptocurrencies. It provides a reliable and secure way for merchants to manage crypto transactions.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Crypto Support:</strong> Accepts Bitcoin, Bitcoin Cash, Ethereum, and more, providing flexibility for customers.</li>
               <li><strong>Real-Time Exchange Rates:</strong> Automatically calculates real-time exchange rates, ensuring accurate pricing and conversion.</li>
               <li><strong>Invoice Management:</strong> Generates and manages invoices, with options for setting payment expiration times and monitoring statuses.</li>
               <li><strong>Refund Handling:</strong> Provides options for issuing refunds directly through the API.</li>
               <li><strong>Enhanced Security:</strong> Offers secure, end-to-end encryption, ensuring all transactions remain private and safe.</li>
           </ul>
           <p>The BitPay API is ideal for businesses looking to expand their payment options to include cryptocurrencies with seamless integration and secure transactions.</p>
           <p>For more information and detailed documentation, visit <a href="https://developer.bitpay.com/docs/getting-started" target="_blank" rel="noopener noreferrer">BitPay’s official developer documentation</a>.</p>
        `,
        meta: {
            title: "BitPay API | Cryptocurrency Payment Integration",
            description: "Integrate cryptocurrency payments into your platform using the BitPay API, supporting Bitcoin, Bitcoin Cash, and secure payment processing.",
            keywords: "BitPay API, cryptocurrency payment gateway, Bitcoin payments, crypto payments integration, secure payment processing, e-commerce crypto"
        }
    },
    {
        title: "NOWPayments API",
        description: "Flexible, non-custodial payment API for accepting over 300 cryptocurrencies.",
        category: "payment",
        link: "nowpayments-api",
        img: "/images/nowpayments.png",
        details: `
           <p>The NOWPayments API allows businesses to accept a wide range of cryptocurrencies for payments, supporting over 300 digital assets. As a non-custodial payment gateway, it offers merchants full control over their funds by sending payments directly to their wallets.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Crypto Support:</strong> Accepts over 300 cryptocurrencies, including Bitcoin, Ethereum, Litecoin, and many altcoins.</li>
               <li><strong>Non-Custodial Service:</strong> Payments are directly sent to the merchant's wallet, eliminating custodial risk.</li>
               <li><strong>Automatic Fiat Conversion:</strong> Offers automatic conversion from crypto to fiat currencies for easy business management.</li>
               <li><strong>Real-Time Payment Notifications:</strong> Webhook support for instant updates on payment statuses.</li>
               <li><strong>Donation and Recurring Payment Options:</strong> Includes options for setting up donations and recurring payments, ideal for subscriptions or ongoing support.</li>
           </ul>
           <p>NOWPayments is ideal for businesses looking to expand their payment options with a secure, versatile crypto payment solution that requires no custodial involvement.</p>
           <p>For more information and detailed documentation, visit <a href="https://nowpayments.io/api" target="_blank" rel="noopener noreferrer">NOWPayments’ official API page</a>.</p>
        `,
        meta: {
            title: "NOWPayments API | Accept Cryptocurrency Payments",
            description: "Integrate NOWPayments API to accept over 300 cryptocurrencies with non-custodial, secure, and real-time payment processing.",
            keywords: "NOWPayments API, cryptocurrency payment gateway, Bitcoin payments, non-custodial crypto payments, multi-crypto support, fiat conversion"
        }
    },
    {
        title: "CoinGate API",
        description: "Versatile payment API for accepting over 50 cryptocurrencies with seamless fiat conversion.",
        category: "payment",
        link: "coingate-api",
        img: "/images/coingate.png",
        details: `
           <p>The CoinGate API enables businesses to accept cryptocurrency payments in over 50 digital assets, including Bitcoin, Ethereum, and Litecoin. It offers a convenient and secure way to integrate crypto payments with support for automated fiat conversions.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Crypto Support:</strong> Accepts over 50 cryptocurrencies, giving customers flexibility in payment options.</li>
               <li><strong>Automatic Fiat Conversion:</strong> Converts received crypto payments to fiat currencies automatically, reducing volatility risks.</li>
               <li><strong>Real-Time Payment Notifications:</strong> Provides webhook support for instant updates on payment statuses.</li>
               <li><strong>E-commerce Integrations:</strong> Offers plugins and integrations for popular e-commerce platforms, such as Shopify and WooCommerce.</li>
               <li><strong>Donation and Invoice Management:</strong> Allows for easy management of donations and invoices, with customizable options for merchants.</li>
           </ul>
           <p>CoinGate is a robust solution for businesses aiming to accept cryptocurrency payments with a user-friendly interface and extensive support for fiat conversions.</p>
           <p>For more information and detailed documentation, visit <a href="https://coingate.com/" target="_blank" rel="noopener noreferrer">CoinGate’s official API page</a>.</p>
        `,
        meta: {
            title: "CoinGate API | Accept Cryptocurrency Payments with Fiat Conversion",
            description: "Use CoinGate API to accept over 50 cryptocurrencies with seamless fiat conversion and integrations for major e-commerce platforms.",
            keywords: "CoinGate API, cryptocurrency payment gateway, Bitcoin payments, Ethereum payments, fiat conversion, multi-crypto support, e-commerce integration"
        }
    },
    {
        title: "Binance Pay API",
        description: "Secure and flexible payment API for accepting cryptocurrency with Binance’s ecosystem.",
        category: "payment",
        link: "binance-pay-api",
        img: "/images/binance.png",
        details: `
           <p>The Binance Pay API allows businesses to accept cryptocurrency payments with support for a wide range of digital assets, including Bitcoin, Ethereum, and more. Designed to offer a secure and efficient payment solution, Binance Pay is ideal for both online and offline transactions.</p>
           <h2>Main Features</h2>
           <ul>
               <li><strong>Multi-Crypto Support:</strong> Accepts multiple cryptocurrencies, giving flexibility to customers for payments.</li>
               <li><strong>Seamless Integration:</strong> Easily integrates into existing platforms with detailed documentation and SDKs for various programming languages.</li>
               <li><strong>Real-Time Notifications:</strong> Provides instant updates on transaction statuses through webhook support.</li>
               <li><strong>Invoice and Refund Management:</strong> Allows for creating invoices and handling refunds directly through the API.</li>
               <li><strong>Security and Compliance:</strong> Leverages Binance's secure infrastructure, ensuring transactions are protected and compliant with regulatory standards.</li>
           </ul>
           <p>Binance Pay is an ideal choice for businesses seeking to expand their payment options with a secure and reputable cryptocurrency payment gateway.</p>
           <p>For more information and detailed documentation, visit <a href="https://developers.binance.com/docs/binance-pay/introduction" target="_blank" rel="noopener noreferrer">Binance Pay’s official API page</a>.</p>
        `,
        meta: {
            title: "Binance Pay API | Secure Cryptocurrency Payment Integration",
            description: "Integrate Binance Pay API to accept multiple cryptocurrencies with secure transaction processing and real-time notifications.",
            keywords: "Binance Pay API, cryptocurrency payment gateway, Bitcoin payments, secure crypto transactions, multi-crypto support, Binance integration"
        }
    },
//itt er veget payment
{
    title: "CoinGecko API",
    description: "Comprehensive data API providing real-time and historical cryptocurrency market information.",
    category: "data",
    link: "coingecko-api",
    img: "/images/coingecko.png",
    details: `
       <p>The CoinGecko API offers extensive cryptocurrency market data, providing developers with real-time prices, historical data, market cap, trading volume, and more for over 15,000 cryptocurrencies.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Real-Time Price Data:</strong> Access up-to-the-minute prices for a wide range of cryptocurrencies.</li>
           <li><strong>Historical Market Data:</strong> Retrieve historical price data and market statistics for comprehensive analysis.</li>
           <li><strong>Market Cap and Volume:</strong> Detailed market cap and trading volume data for thousands of assets.</li>
           <li><strong>Exchanges and Pairs Data:</strong> Information on trading pairs and data from numerous exchanges worldwide.</li>
           <li><strong>Developer-Friendly:</strong> Free tier available with well-documented endpoints for easy integration.</li>
       </ul>
       <p>CoinGecko API is an ideal solution for applications requiring reliable and comprehensive cryptocurrency data, supporting developers with a robust and flexible data source.</p>
       <p>For more information and detailed documentation, visit <a href="https://www.coingecko.com/en/api" target="_blank" rel="noopener noreferrer">CoinGecko’s official API page</a>.</p>
    `,
    meta: {
        title: "CoinGecko API | Real-Time and Historical Cryptocurrency Data",
        description: "Utilize the CoinGecko API to access comprehensive real-time and historical market data for over 15,000 cryptocurrencies.",
        keywords: "CoinGecko API, cryptocurrency data API, real-time prices, historical data, market cap, trading volume, crypto market information"
    }
},
{
    title: "CoinMarketCap API",
    description: "Powerful API providing real-time and historical cryptocurrency market data and insights.",
    category: "data",
    link: "coinmarketcap-api",
    img: "/images/coinmarketcap.png",
    details: `
       <p>The CoinMarketCap API delivers accurate and extensive data on cryptocurrency markets, including real-time price tracking, historical data, and detailed market insights for thousands of cryptocurrencies.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Real-Time Price Data:</strong> Access live price updates for thousands of cryptocurrencies.</li>
           <li><strong>Market Cap and Volume:</strong> Provides market cap, trading volume, and other key metrics for market analysis.</li>
           <li><strong>Historical Data:</strong> Retrieve historical price data to support trend analysis and backtesting.</li>
           <li><strong>Exchange and Trading Pair Information:</strong> Get detailed data on exchanges, trading pairs, and liquidity.</li>
           <li><strong>Global Metrics:</strong> Track overall market trends with data on total market cap, BTC dominance, and more.</li>
       </ul>
       <p>The CoinMarketCap API is an excellent tool for developers needing comprehensive market data, supporting both free and premium tiers for various data access levels.</p>
       <p>For more information and detailed documentation, visit <a href="https://coinmarketcap.com/api/" target="_blank" rel="noopener noreferrer">CoinMarketCap’s official API page</a>.</p>
    `,
    meta: {
        title: "CoinMarketCap API | Real-Time and Historical Cryptocurrency Market Data",
        description: "Utilize the CoinMarketCap API to access comprehensive real-time and historical market data, exchange information, and global crypto metrics.",
        keywords: "CoinMarketCap API, cryptocurrency data API, real-time prices, historical data, market cap, trading volume, crypto market insights"
    }
    
},
{
    title: "CryptoCompare API",
    description: "Comprehensive API offering real-time and historical cryptocurrency market data, social insights, and blockchain analytics.",
    category: "data",
    link: "cryptocompare-api",
    img: "/images/cryptocompare.png",
    details: `
       <p>The CryptoCompare API provides developers with access to a wide range of cryptocurrency data, including real-time prices, historical data, social metrics, and blockchain analytics. It supports over 5,700 coins and 260,000 currency pairs, making it a valuable resource for building crypto-related applications.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Real-Time Price Data:</strong> Access live price information for thousands of cryptocurrencies across multiple exchanges.</li>
           <li><strong>Historical Data:</strong> Retrieve historical price data, including daily, hourly, and minute-level information for comprehensive analysis.</li>
           <li><strong>Social Insights:</strong> Gain insights into social media trends and sentiment analysis related to various cryptocurrencies.</li>
           <li><strong>Blockchain Analytics:</strong> Access on-chain data and analytics to understand blockchain activity and trends.</li>
           <li><strong>News and Updates:</strong> Stay informed with the latest news articles and updates from the cryptocurrency world.</li>
       </ul>
       <p>The CryptoCompare API is ideal for developers seeking a robust and versatile data source for cryptocurrency market analysis, portfolio tracking, and application development.</p>
       <p>For more information and detailed documentation, visit <a href="https://min-api.cryptocompare.com/" target="_blank" rel="noopener noreferrer">CryptoCompare’s official API page</a>.</p>
    `,
    meta: {
        title: "CryptoCompare API | Real-Time and Historical Cryptocurrency Data",
        description: "Utilize the CryptoCompare API to access comprehensive real-time and historical market data, social insights, and blockchain analytics for over 5,700 cryptocurrencies.",
        keywords: "CryptoCompare API, cryptocurrency data API, real-time prices, historical data, social insights, blockchain analytics, crypto market data"
    }
},
{
    title: "Messari API",
    description: "Comprehensive API providing quantitative and qualitative cryptocurrency data for market analysis and research.",
    category: "data",
    link: "messari-api",
    img: "/images/messari.png",
    details: `
       <p>The Messari API offers developers access to a wide range of cryptocurrency data, including market data, real-time events, governance information, news, research reports, and diligence reports. It is designed to support quantitative and qualitative analysis for informed decision-making in the crypto markets.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Market Data:</strong> Access price, volume, all-time high (ATH), and return on investment (ROI) data for over 13,000 assets across more than 150 exchanges.</li>
           <li><strong>Intel Events:</strong> Receive real-time tracking of events for over 400 assets, including client releases, hacks, blockchain halts, network upgrades, legal or regulatory actions, and changes to project teams.</li>
           <li><strong>News:</strong> Stay updated with news articles from trusted crypto media outlets and project blogs, including AI-generated summaries for each piece of content.</li>
           <li><strong>Token Unlocks:</strong> Access token vesting schedules, distributions, stakeholder information, and unlock events for over 90 assets.</li>
           <li><strong>Governance:</strong> Retrieve proposals, DAO information, and governance metrics for over 100 protocols.</li>
           <li><strong>Research Reports:</strong> Access Messari’s best-in-class research and diligence reports directly via the API.</li>
       </ul>
       <p>The Messari API is ideal for developers, investors, compliance teams, and regulators seeking comprehensive and reliable cryptocurrency data to power their workflows and make informed decisions.</p>
       <p>For more information and detailed documentation, visit <a href="https://messari.io/api" target="_blank" rel="noopener noreferrer">Messari’s official API documentation</a>.</p>
    `,
    meta: {
        title: "Messari API | Comprehensive Cryptocurrency Data for Market Analysis",
        description: "Utilize the Messari API to access quantitative and qualitative cryptocurrency data, including market data, real-time events, governance information, news, research reports, and diligence reports.",
        keywords: "Messari API, cryptocurrency data API, market data, real-time events, governance, news, research reports, diligence reports, crypto market analysis"
    }
},
{
    title: "Glassnode API",
    description: "Advanced on-chain and financial market data API for comprehensive blockchain analysis.",
    category: "data",
    link: "glassnode-api",
    img: "/images/glassnode.png",
    details: `
       <p>The Glassnode API provides developers with access to a vast array of on-chain and financial market data, enabling in-depth analysis of blockchain networks and cryptocurrency markets. It offers a unified REST API delivering live and historical data across multiple metrics.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>On-Chain Data:</strong> Access metrics such as transaction volumes, active addresses, and supply distributions for various blockchain networks.</li>
           <li><strong>Market Data:</strong> Retrieve market capitalization, trading volumes, and other financial indicators for cryptocurrencies.</li>
           <li><strong>Entity-Based Metrics:</strong> Analyze data aggregated by entities, providing insights into behaviors of different market participants.</li>
           <li><strong>High Performance:</strong> Benefit from a robust infrastructure ensuring reliable and fast data retrieval.</li>
           <li><strong>Comprehensive Documentation:</strong> Utilize detailed guides and tutorials to facilitate seamless integration.</li>
       </ul>
       <p>The Glassnode API is ideal for developers, analysts, and researchers seeking detailed blockchain and market data to inform their projects and strategies.</p>
       <p>For more information and detailed documentation, visit <a href="https://glassnode.com/" target="_blank" rel="noopener noreferrer">Glassnode’s official API documentation</a>.</p>
    `,
    meta: {
        title: "Glassnode API | Advanced On-Chain and Market Data for Blockchain Analysis",
        description: "Utilize the Glassnode API to access comprehensive on-chain and financial market data, enabling in-depth blockchain analysis and insights.",
        keywords: "Glassnode API, blockchain data API, on-chain metrics, cryptocurrency market data, blockchain analysis, crypto analytics"
    }
},
{
    title: "Santiment API",
    description: "Comprehensive API providing on-chain, social, developer activity, and pricing data for over 2,000 crypto assets.",
    category: "data",
    link: "santiment-api",
    img: "/images/santiment.png",
    details: `
       <p>The Santiment API offers developers access to a wide range of cryptocurrency data, including on-chain metrics, social trends, developer activity, and pricing information. It supports over 2,000 crypto assets, enabling comprehensive market analysis and strategy development.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>On-Chain Data:</strong> Access metrics such as transaction volumes, active addresses, and token circulation across multiple blockchains.</li>
           <li><strong>Social Data:</strong> Analyze social media trends, sentiment, and discussion rates related to various cryptocurrencies.</li>
           <li><strong>Developer Activity:</strong> Monitor development activity, including GitHub commits and repository contributions, to assess project progress.</li>
           <li><strong>Pricing Data:</strong> Retrieve real-time and historical price data for a wide range of crypto assets.</li>
           <li><strong>GraphQL Interface:</strong> Utilize a flexible GraphQL API for precise data queries and efficient data retrieval.</li>
       </ul>
       <p>The Santiment API is ideal for developers, analysts, and investors seeking a holistic view of the cryptocurrency market through diverse data points.</p>
       <p>For more information and detailed documentation, visit <a href="https://api.santiment.net/" target="_blank" rel="noopener noreferrer">Santiment’s official API page</a>.</p>
    `,
    meta: {
        title: "Santiment API | Comprehensive Cryptocurrency Data for Market Analysis",
        description: "Utilize the Santiment API to access on-chain, social, developer activity, and pricing data for over 2,000 crypto assets, enabling comprehensive market analysis.",
        keywords: "Santiment API, cryptocurrency data API, on-chain metrics, social data, developer activity, pricing data, crypto market analysis"
    }
},
{
    title: "IntoTheBlock API",
    description: "Comprehensive API providing on-chain analytics, DeFi insights, and market indicators for cryptocurrencies.",
    category: "data",
    link: "intotheblock-api",
    img: "/images/intotheblock.png",
    details: `
       <p>The IntoTheBlock API offers developers access to a wide range of cryptocurrency data, including on-chain analytics, DeFi insights, and market indicators. It supports multiple blockchain networks and provides detailed metrics to inform investment strategies and market analysis.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>On-Chain Analytics:</strong> Access metrics such as transaction volumes, active addresses, and token distributions across various blockchains.</li>
           <li><strong>DeFi Insights:</strong> Analyze decentralized finance protocols with data on liquidity, lending, and borrowing activities.</li>
           <li><strong>Market Indicators:</strong> Retrieve indicators like volatility, correlations, and sentiment analysis to assess market conditions.</li>
           <li><strong>Ownership Metrics:</strong> Examine token holder distributions, concentration, and holding periods for different assets.</li>
           <li><strong>Developer-Friendly:</strong> Utilize well-documented endpoints and SDKs for seamless integration into applications.</li>
       </ul>
       <p>The IntoTheBlock API is ideal for developers, analysts, and investors seeking in-depth cryptocurrency data to enhance their market understanding and decision-making processes.</p>
       <p>For more information and detailed documentation, visit <a href="https://www.intotheblock.com/api" target="_blank" rel="noopener noreferrer">IntoTheBlock’s official API page</a>.</p>
    `,
    meta: {
        title: "IntoTheBlock API | Comprehensive Cryptocurrency Data and Analytics",
        description: "Utilize the IntoTheBlock API to access on-chain analytics, DeFi insights, and market indicators for informed cryptocurrency market analysis.",
        keywords: "IntoTheBlock API, cryptocurrency data API, on-chain analytics, DeFi insights, market indicators, crypto market analysis"
    }
},

//itt er veget a 
{
    title: "Etherscan API",
    description: "Comprehensive API for accessing Ethereum blockchain data, including transactions, balances, and smart contract information.",
    category: "explorer",
    link: "etherscan-api",
    img: "/images/etherscan.png",
    details: `
       <p>The Etherscan API provides developers with extensive access to Ethereum blockchain data, allowing them to retrieve information on account balances, transaction histories, and smart contract interactions. Etherscan’s API is a valuable tool for building Ethereum-based applications.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Account Information:</strong> Retrieve balances, transaction counts, and historical data for Ethereum addresses.</li>
           <li><strong>Transaction Data:</strong> Access detailed transaction information, including gas fees, timestamps, and status.</li>
           <li><strong>Smart Contract Interaction:</strong> Query smart contract events, methods, and state for in-depth contract analysis.</li>
           <li><strong>Blockchain Data:</strong> Retrieve block data, such as miner information, block rewards, and uncle blocks.</li>
           <li><strong>Token Data:</strong> Access information on ERC-20 and ERC-721 tokens, including transfers, holders, and metadata.</li>
       </ul>
       <p>The Etherscan API is ideal for developers looking to build applications that require Ethereum blockchain data, offering a reliable and comprehensive source for real-time and historical data.</p>
       <p>For more information and detailed documentation, visit <a href="https://docs.etherscan.io/" target="_blank" rel="noopener noreferrer">Etherscan’s official API documentation</a>.</p>
    `,
    meta: {
        title: "Etherscan API | Ethereum Blockchain Data and Analytics",
        description: "Utilize the Etherscan API to access detailed Ethereum blockchain data, including account information, transaction history, and smart contract interactions.",
        keywords: "Etherscan API, Ethereum data API, blockchain explorer API, Ethereum transactions, smart contract data, blockchain analytics"
    }
},
{
    title: "BscScan API",
    description: "Comprehensive API for accessing Binance Smart Chain data, including transactions, account balances, and smart contract information.",
    category: "explorer",
    link: "bscscan-api",
    img: "/images/bscscan.png",
    details: `
       <p>The BscScan API provides developers with extensive access to Binance Smart Chain (BSC) data, enabling retrieval of information on account balances, transaction histories, and smart contract interactions. BscScan’s API is a valuable tool for building BSC-based applications.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Account Information:</strong> Retrieve balances, transaction counts, and historical data for BSC addresses.</li>
           <li><strong>Transaction Data:</strong> Access detailed transaction information, including gas fees, timestamps, and status.</li>
           <li><strong>Smart Contract Interaction:</strong> Query smart contract events, methods, and state for in-depth contract analysis.</li>
           <li><strong>Blockchain Data:</strong> Retrieve block data, such as miner information, block rewards, and uncle blocks.</li>
           <li><strong>Token Data:</strong> Access information on BEP-20 and BEP-721 tokens, including transfers, holders, and metadata.</li>
       </ul>
       <p>The BscScan API is ideal for developers looking to build applications that require Binance Smart Chain data, offering a reliable and comprehensive source for real-time and historical data.</p>
       <p>For more information and detailed documentation, visit <a href="https://docs.bscscan.com/" target="_blank" rel="noopener noreferrer">BscScan’s official API documentation</a>.</p>
    `,
    meta: {
        title: "BscScan API | Binance Smart Chain Data and Analytics",
        description: "Utilize the BscScan API to access detailed Binance Smart Chain data, including account information, transaction history, and smart contract interactions.",
        keywords: "BscScan API, Binance Smart Chain data API, blockchain explorer API, BSC transactions, smart contract data, blockchain analytics"
    }
},
{
    title: "Blockchain.com API",
    description: "Comprehensive API suite providing access to blockchain data, market data, and payment processing services.",
    category: "explorer",
    link: "blockchain-com-api",
    img: "/images/blockchain-com.png",
    details: `
       <p>The Blockchain.com API offers developers a robust set of tools to interact with blockchain data, access real-time market information, and integrate payment processing services. It supports multiple cryptocurrencies, including Bitcoin and Ethereum, facilitating the development of diverse blockchain-based applications.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Blockchain Data Access:</strong> Retrieve information on blocks, transactions, and addresses across supported blockchains.</li>
           <li><strong>Market Data:</strong> Access real-time and historical market data, including prices, trading volumes, and exchange rates.</li>
           <li><strong>Payment Processing:</strong> Integrate payment services to facilitate cryptocurrency transactions within applications.</li>
           <li><strong>Websocket Support:</strong> Utilize websocket connections for low-latency streaming of market and blockchain data.</li>
           <li><strong>Developer-Friendly:</strong> Comprehensive documentation and support for seamless integration into various applications.</li>
       </ul>
       <p>The Blockchain.com API is ideal for developers seeking a versatile and reliable solution for accessing blockchain data, market information, and payment processing capabilities.</p>
       <p>For more information and detailed documentation, visit <a href="https://www.blockchain.com/api" target="_blank" rel="noopener noreferrer">Blockchain.com’s official API page</a>.</p>
    `,
    meta: {
        title: "Blockchain.com API | Blockchain Data, Market Information, and Payment Processing",
        description: "Utilize the Blockchain.com API to access comprehensive blockchain data, real-time market information, and integrate cryptocurrency payment processing services.",
        keywords: "Blockchain.com API, blockchain data API, market data API, cryptocurrency payment processing, blockchain explorer API, real-time market information"
    }
},
{
    title: "Blockchair API",
    description: "Comprehensive API providing access to data from multiple blockchains, including Bitcoin, Ethereum, and more.",
    category: "explorer",
    link: "blockchair-api",
    img: "/images/blockchair.png",
    details: `
       <p>The Blockchair API offers developers access to data from multiple blockchains, including Bitcoin, Ethereum, and others. It supports numerous analytical queries like filtering, sorting, and aggregating blockchain data, making it a valuable tool for building blockchain-based applications.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Multi-Blockchain Support:</strong> Access data from 14 different blockchains, including Bitcoin, Ethereum, Litecoin, and more.</li>
           <li><strong>Analytical Queries:</strong> Perform filtering, sorting, and aggregating of blockchain data for in-depth analysis.</li>
           <li><strong>Real-Time Data:</strong> Retrieve up-to-date information on blocks, transactions, addresses, and more.</li>
           <li><strong>Developer-Friendly:</strong> Comprehensive documentation and support for seamless integration into various applications.</li>
           <li><strong>Privacy-Focused:</strong> Blockchair emphasizes user privacy, ensuring that personal data is neither collected nor shared.</li>
       </ul>
       <p>The Blockchair API is ideal for developers seeking a versatile and reliable solution for accessing and analyzing blockchain data across multiple networks.</p>
       <p>For more information and detailed documentation, visit <a href="https://blockchair.com/api/docs" target="_blank" rel="noopener noreferrer">Blockchair’s official API documentation</a>.</p>
    `,
    meta: {
        title: "Blockchair API | Multi-Blockchain Data Access and Analytics",
        description: "Utilize the Blockchair API to access and analyze data from multiple blockchains, including Bitcoin, Ethereum, and more.",
        keywords: "Blockchair API, blockchain data API, multi-blockchain explorer API, Bitcoin data, Ethereum data, blockchain analytics"
    }
},
{
    title: "Solscan API",
    description: "Comprehensive API providing access to Solana blockchain data, including transactions, accounts, and token information.",
    category: "explorer",
    link: "solscan-api",
    img: "/images/solscan.png",
    details: `
       <p>The Solscan API offers developers extensive access to Solana blockchain data, enabling retrieval of information on transactions, accounts, tokens, and more. It serves as a valuable tool for building applications that interact with the Solana ecosystem.</p>
       <h2>Main Features</h2>
       <ul>
           <li><strong>Transaction Data:</strong> Access detailed information on Solana transactions, including signatures, statuses, and timestamps.</li>
           <li><strong>Account Information:</strong> Retrieve data on account balances, ownership, and activity within the Solana network.</li>
           <li><strong>Token Details:</strong> Obtain information on SPL tokens, including metadata, holders, and supply statistics.</li>
           <li><strong>Block Data:</strong> Access information on Solana blocks, such as block height, time, and leader details.</li>
           <li><strong>Developer-Friendly:</strong> Comprehensive documentation and support for seamless integration into various applications.</li>
       </ul>
       <p>The Solscan API is ideal for developers looking to build applications that require Solana blockchain data, offering a reliable and comprehensive source for real-time and historical information.</p>
       <p>For more information and detailed documentation, visit <a href="https://docs.solscan.io/" target="_blank" rel="noopener noreferrer">Solscan’s official API documentation</a>.</p>
    `,
    meta: {
        title: "Solscan API | Solana Blockchain Data and Analytics",
        description: "Utilize the Solscan API to access detailed Solana blockchain data, including transaction information, account details, and token analytics.",
        keywords: "Solscan API, Solana data API, blockchain explorer API, Solana transactions, account data, token information, blockchain analytics"
    }
}



    
    
    // További API-k adatai
];

function ApiDetailPage() {
    const { category, api } = useParams();
    const apiDetail = apiData.find(apiItem => apiItem.category === category && apiItem.link === api);
    const location = useLocation();
    const canonicalUrl = `https://jsoroko.com${location.pathname}`;
    if (!apiDetail) {
        return <p>API not found.</p>;
    }

    return (
        <div className={styles.detailContainer}>
            <Helmet>
                <title>{apiDetail.meta.title}</title>
                <link rel="canonical" href={canonicalUrl} />

                <meta name="description" content={apiDetail.meta.description} />
                <meta name="keywords" content={apiDetail.meta.keywords} />
            </Helmet>
            <img src={apiDetail.img} alt={`${apiDetail.title} Logo`} className={styles.detailImage} loading="lazy" />
            <h1>{apiDetail.title}</h1>
            <p className={styles.detailDescription}>{apiDetail.description}</p>
            <div className={styles.detailContent} dangerouslySetInnerHTML={{ __html: apiDetail.details }} />
        </div>
    );
}

export default ApiDetailPage;
