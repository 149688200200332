import React, { } from 'react';
import { BrowserRouter as Router, Route, Routes,} from 'react-router-dom';
import { } from 'ethers';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import AddExtraFeatures from './components/AddExtraFeatures';

import CryptoNews from './CryptoNews';
import SinglePost from './SinglePost';
import HomePage from './HomePage';

import Learn from './Learn';
import ArticlePage from './ArticlePage';
import CryptoCalculatorPage from './CryptoCalculatorPage';
import Library from './Library';
import ApiCategoryList from './ApiCategoryList';
import ApiList from './ApiList';
import ApiDetailPage from './ApiDetailPage';
import NftTrading from './NftTrading';
import NftDetailPage from './NftDetailPage';
import P2EDetailPage from './P2EDetailPage';   
import P2EGames from './P2EGames';
import MemeCoinAnalytics from './MemeCoinAnalytics';
import MemeCoinAnalyticsDetail from './MemeCoinAnalyticsDetail';
import CryptoGamblingSites from './CryptoGamblingSites';
import CryptoGamblingSiteDetail from './CryptoGamblingSiteDetail';
import TopDefiApps from './TopDefiApps';
import TopDefiAppsDetail from './TopDefiAppsDetail';
import Blockchains from './Blockchains';
import BlockchainsDetail from './BlockchainsDetail';
import CryptoExchanges from './CryptoExchanges';
import CryptoExchangeDetail from './CryptoExchangeDetail';
import NodeDetail from './NodeDetail';
import Nodes from './Nodes';
import BaseNetworkPage from './BaseNetworkPage'; // Új import
import Wallet from './wallet';
import WalletDetail from './walletpage';


// További importok itt

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<HomePage />} />
        <Route path="/base-network" element={<BaseNetworkPage />} />
        <Route path="/add-features" element={<AddExtraFeatures />} />
        <Route path="/crypto-news" element={<CryptoNews />} />
        <Route path="/post/:slug" element={<SinglePost />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/learn/:slug" element={<ArticlePage />} />
        <Route path="/category/:slug" element={<CryptoNews />} />
        <Route path="/library" element={<Library />} />
        <Route path="/library/api" element={<ApiCategoryList />} />
        <Route path="/library/api/:category" element={<ApiList />} />
        <Route path="/library/api/:category/:api" element={<ApiDetailPage />} />
        <Route path="/library/nft-trading" element={<NftTrading />} />
        <Route path="/library/nft-trading/:platform" element={<NftDetailPage />} />
        <Route path="/library/p2e-games" element={<P2EGames />} />
        <Route path="/library/p2e-games/:game" element={<P2EDetailPage />} />
        <Route path="/library/crypto-gambling-sites" element={<CryptoGamblingSites />} />
        <Route path="/library/crypto-gambling-sites/:site" element={<CryptoGamblingSiteDetail />} />
        <Route path="/calculator" element={<CryptoCalculatorPage />} />
        <Route path="/library/meme-coin-analytics" element={<MemeCoinAnalytics />} />
        <Route path="/library/meme-coin-analytics/:platform" element={<MemeCoinAnalyticsDetail />} />
        <Route path="/library/defi-apps" element={<TopDefiApps />} />
        <Route path="/library/defi-apps/:platform" element={<TopDefiAppsDetail />} />
        <Route path="/library/blockchains" element={<Blockchains />} />
        <Route path="/library/blockchains/:platform" element={<BlockchainsDetail />} />
        <Route path="/library/crypto-exchanges" element={<CryptoExchanges />} />
        <Route path="/library/crypto-exchanges/:platform" element={<CryptoExchangeDetail />} />
        <Route path="/library/nodes" element={<Nodes />} />
        <Route path="/library/nodes/:node" element={<NodeDetail />} />
        <Route path="/library/wallets" element={<Wallet />} />
<Route path="/library/wallets/:wallet" element={<WalletDetail />} />

      </Routes>
    </Router>
  );
}

export default App;
