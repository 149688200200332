import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './P2EDetailPage.module.css';

const gameDetails = {
    "axie-infinity": {
        name: "Axie Infinity",
        metaTitle: "Axie Infinity | Play-to-Earn Blockchain Game",
        metaDescription: "Axie Infinity is a top blockchain game where players can battle, breed, and trade fantasy creatures called Axies. Earn tokens by participating in battles and various activities.",
        description: "Axie Infinity is a popular blockchain-based game where players can battle, breed, and trade fantasy creatures called Axies. It offers a play-to-earn model where players can earn tokens by participating in various in-game activities.",
        details: `
            <p>Axie Infinity is a revolutionary play-to-earn blockchain game, allowing players to collect, breed, and battle creatures known as Axies. Created by Sky Mavis, Axie Infinity uses the Ethereum blockchain to offer players real ownership of in-game assets, each represented as a unique NFT.</p>
            
            <h2>About Axie Infinity</h2>
            <p>In Axie Infinity, players earn tokens like Smooth Love Potion (SLP) and Axie Infinity Shards (AXS) through various activities, including battles and breeding. The game fosters a strategic battle system, where players can use their Axies’ unique abilities to compete and earn rewards. Additionally, Axies can be bred to produce offspring with new traits, making each Axie unique and valuable within the ecosystem.</p>
            
            <h2>Main Features</h2>
            <ul>
                <li><strong>Strategic Battle Mechanics:</strong> Engage in turn-based battles with Axies, each with its own stats and abilities.</li>
                <li><strong>Breeding System:</strong> Breed Axies to create unique offspring with diverse traits, adding value to your collection.</li>
                <li><strong>Play-to-Earn Rewards:</strong> Earn tokens like SLP and AXS by participating in battles and completing in-game tasks.</li>
                <li><strong>Marketplace for Trading:</strong> Trade, buy, or sell Axies and other assets in a decentralized marketplace, providing liquidity and value to the in-game items.</li>
            </ul>
            
            <h2>Community and Growth</h2>
            <p>Axie Infinity has cultivated a dedicated and vibrant community. The platform regularly introduces updates, events, and rewards, keeping the community engaged. With its player-driven economy and NFT-based assets, Axie Infinity continues to grow as a leading blockchain game, attracting players and investors worldwide.</p>
            
            <p>For more information or to start playing, visit the official <a href="https://axieinfinity.com" target="_blank" rel="noopener noreferrer">Axie Infinity website</a>.</p>
        `,
        img: "/images/axie-infinity.png"
    },
    "the-sandbox": {
    name: "The Sandbox",
    metaTitle: "The Sandbox | Virtual Metaverse on Ethereum",
    metaDescription: "The Sandbox is a decentralized metaverse where players can build, own, and monetize gaming experiences on the blockchain. Discover, create, and trade assets in this vibrant virtual world.",
    description: "The Sandbox is a virtual metaverse where players can build, own, and monetize their gaming experiences on the Ethereum blockchain. It allows players to earn through creating, sharing, and trading assets.",
    details: `
        <p>The Sandbox is a decentralized virtual world built on the Ethereum blockchain, where players and creators come together to build immersive experiences and monetizable assets. This metaverse provides the tools and space for anyone to create, own, and profit from their contributions.</p>
        
        <h2>About The Sandbox</h2>
        <p>In The Sandbox, players can purchase virtual plots of land, develop games, and build communities. All assets are represented as NFTs, allowing true ownership and secure trading within a decentralized marketplace. This innovative ecosystem supports a thriving virtual economy where creativity is rewarded.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Build and Monetize:</strong> Utilize the Game Maker tool to create interactive experiences and games, and monetize them through in-game purchases or asset sales.</li>
            <li><strong>Virtual Real Estate:</strong> Buy, sell, and develop LANDs within The Sandbox, each representing a unique piece of digital real estate.</li>
            <li><strong>Decentralized Marketplace:</strong> Trade user-generated content, NFTs, and other in-game assets on The Sandbox's marketplace, fostering a vibrant economy.</li>
            <li><strong>Empowerment through NFTs:</strong> Each asset is represented as an NFT, allowing players to own and transfer their digital creations securely.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>The Sandbox is more than just a game; it’s a platform for creativity and community. Regular events, collaborations, and updates keep the community engaged. This metaverse provides opportunities for creators, players, and investors to be part of an ever-evolving ecosystem, building value together.</p>
        
        <p>For more information or to start creating, visit the official <a href="https://www.sandbox.game" target="_blank" rel="noopener noreferrer">The Sandbox website</a>.</p>
    `,
    img: "/images/the-sandbox.png"
},
"gods-unchained": {
    name: "Gods Unchained",
    metaTitle: "Gods Unchained | Blockchain-Based Trading Card Game",
    metaDescription: "Gods Unchained is a competitive blockchain-based trading card game where players truly own their cards. Play, earn, and trade cards in a strategic environment.",
    description: "Gods Unchained is a competitive trading card game where players truly own their cards, thanks to blockchain technology. Earn cards by playing and competing against others.",
    details: `
        <p>Gods Unchained is a free-to-play, blockchain-based trading card game that gives players true ownership of their digital cards. Developed by Immutable, this game combines strategic, skill-based card battles with the benefits of decentralized asset ownership on the Ethereum blockchain.</p>
        
        <h2>About Gods Unchained</h2>
        <p>In Gods Unchained, players collect and build decks from a variety of cards representing powerful gods, mythical creatures, and magical spells. Each match requires strategic thinking and deck-building skills to reduce the opponent's life total to zero and claim victory. The unique aspect of Gods Unchained is that each card is a non-fungible token (NFT), granting players the ability to trade, sell, or keep their cards with true ownership.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Strategic Gameplay:</strong> Engage in turn-based matches that require tactical decision-making and strategic deck-building to defeat opponents.</li>
            <li><strong>True Ownership of Cards:</strong> Each card is an NFT on the Ethereum blockchain, allowing players to fully own, trade, or sell their cards, giving real-world value to in-game assets.</li>
            <li><strong>Competitive Play and Rewards:</strong> Participate in ranked matches and tournaments, earn rewards, and climb the leaderboards to showcase your skills.</li>
            <li><strong>Constant Updates:</strong> The game regularly introduces new expansions and balance changes, keeping gameplay fresh and dynamic for the community.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>Gods Unchained has a dedicated and active community, with frequent interactions and updates from the development team. The game hosts tournaments, community events, and offers regular rewards to keep players engaged. Its innovative combination of strategic gameplay and blockchain technology has attracted both traditional gamers and crypto enthusiasts, helping it grow rapidly in popularity.</p>
        
        <p>For more information or to start playing, visit the official <a href="https://godsunchained.com" target="_blank" rel="noopener noreferrer">Gods Unchained website</a>.</p>
    `,
    img: "/images/gods-unchained.png"
},
"decentraland": {
    name: "Decentraland",
    metaTitle: "Decentraland | Virtual World on the Blockchain",
    metaDescription: "Decentraland is a decentralized virtual world on the Ethereum blockchain where users can buy, build, and trade digital real estate and interact within a 3D metaverse.",
    description: "Decentraland is a decentralized virtual world that enables users to own, create, and trade digital assets. Built on the Ethereum blockchain, it offers a unique space for digital land ownership and interactive experiences.",
    details: `
        <p>Decentraland is a blockchain-based virtual world where users can purchase parcels of land (LAND) and build immersive experiences. Leveraging Ethereum, Decentraland allows for true ownership of in-game assets, enabling players to create, explore, and monetize their digital environments.</p>

        <h2>About Decentraland</h2>
        <p>In Decentraland, users engage in a variety of activities, from social interactions to marketplace trading and event participation. Each plot of land can be developed to host buildings, games, artwork, or social hubs, offering endless possibilities for creativity.</p>

        <h2>Main Features</h2>
        <ul>
            <li><strong>Virtual Real Estate (LAND):</strong> Purchase and develop digital land to create unique spaces within the metaverse.</li>
            <li><strong>Decentralized Governance:</strong> Participate in governance through the Decentraland DAO, where users influence the platform's future.</li>
            <li><strong>Marketplace:</strong> Trade avatars, wearables, and land parcels using MANA, the native cryptocurrency of Decentraland.</li>
            <li><strong>Community Events:</strong> Join virtual events like concerts, art shows, and meetups, hosted regularly within the platform.</li>
        </ul>

        <h2>Community and Growth</h2>
        <p>Decentraland has built a vibrant and creative community. Its decentralized governance model empowers users to actively shape its evolution, creating a unique player-driven ecosystem within the blockchain gaming space.</p>

        <p>For more information or to start exploring, visit the official <a href="https://decentraland.org" target="_blank" rel="noopener noreferrer">Decentraland website</a>.</p>
    `,
    img: "/images/decentraland.png"
},

"splinterlands": {
    name: "Splinterlands",
    metaTitle: "Splinterlands | Play-to-Earn Digital Card Game",
    metaDescription: "Splinterlands is a blockchain-based digital card game where players can collect, trade, and battle with unique NFT cards. Earn rewards by participating in battles and tournaments.",
    description: "Splinterlands is a popular blockchain-based digital card game that allows players to collect, trade, and battle with unique NFT cards. It offers a play-to-earn model where players can earn rewards by participating in various in-game activities.",
    details: `
        <p>Splinterlands is a revolutionary play-to-earn digital card game built on blockchain technology. Players can collect, trade, and battle with unique NFT cards, each representing different creatures, abilities, and stats. The game operates on the Hive blockchain, ensuring true ownership and transparency of in-game assets.</p>
        
        <h2>About Splinterlands</h2>
        <p>In Splinterlands, players engage in strategic battles using their collection of cards. Each card belongs to a specific faction, known as a "Splinter," and possesses unique abilities and stats. Players can participate in ranked battles, tournaments, and special events to earn rewards, including Dark Energy Crystals (DEC) and other valuable assets.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Strategic Gameplay:</strong> Build and customize your deck to outsmart opponents in fast-paced, automated battles.</li>
            <li><strong>Card Collection and Trading:</strong> Collect a wide variety of cards, each with unique abilities, and trade them in a decentralized marketplace.</li>
            <li><strong>Play-to-Earn Rewards:</strong> Earn DEC and other rewards by participating in battles, completing quests, and ranking high in tournaments.</li>
            <li><strong>Decentralized Marketplace:</strong> Buy, sell, and rent cards and other assets in a player-driven economy.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>Splinterlands has cultivated a dedicated and active community. The platform regularly introduces updates, new cards, and events to keep the gameplay fresh and engaging. With its player-driven economy and NFT-based assets, Splinterlands continues to grow as a leading blockchain game, attracting players and investors worldwide.</p>
        
        <p>For more information or to start playing, visit the official <a href="https://splinterlands.com" target="_blank" rel="noopener noreferrer">Splinterlands website</a>.</p>
    `,
    img: "/images/splinterlands.png"
},
"illuvium": {
    name: "Illuvium",
    metaTitle: "Illuvium | Play-to-Earn Blockchain Game",
    metaDescription: "Illuvium is a blockchain-based open-world RPG where players can explore, capture creatures called Illuvials, and engage in auto-battler combat. Earn rewards through gameplay and trading.",
    description: "Illuvium is a blockchain-based open-world RPG that allows players to explore diverse landscapes, capture creatures known as Illuvials, and participate in strategic auto-battler combat. It offers a play-to-earn model where players can earn rewards through various in-game activities.",
    details: `
        <p>Illuvium is a groundbreaking play-to-earn blockchain game that combines open-world exploration with strategic auto-battler mechanics. Built on the Ethereum blockchain, Illuvium provides players with true ownership of in-game assets, each represented as unique NFTs.</p>
        
        <h2>About Illuvium</h2>
        <p>In Illuvium, players embark on an adventure across a vast and visually stunning world, encountering and capturing creatures known as Illuvials. These creatures can be trained and used in battles against other players or AI opponents. The game features a rich storyline, diverse biomes, and a dynamic ecosystem that evolves based on player interactions.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Open-World Exploration:</strong> Traverse a beautifully crafted world with diverse regions, each inhabited by unique Illuvials.</li>
            <li><strong>Illuvial Capture and Training:</strong> Discover, capture, and train Illuvials, each with distinct abilities and attributes.</li>
            <li><strong>Strategic Auto-Battler Combat:</strong> Engage in tactical battles where team composition and strategy are key to victory.</li>
            <li><strong>Play-to-Earn Rewards:</strong> Earn in-game currency and assets through exploration, battles, and trading.</li>
            <li><strong>Decentralized Marketplace:</strong> Trade Illuvials and other assets in a player-driven economy.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>Illuvium has fostered a vibrant and engaged community. The development team actively collaborates with players, incorporating feedback to enhance the gaming experience. Regular updates, events, and expansions keep the gameplay fresh and exciting, attracting a growing number of players and investors to the ecosystem.</p>
        
        <p>For more information or to start playing, visit the official <a href="https://illuvium.io" target="_blank" rel="noopener noreferrer">Illuvium website</a>.</p>
    `,
    img: "/images/illuvium.png"
},
"defikingdoms": {
    name: "DefiKingdoms",
    metaTitle: "DeFi Kingdoms | Play-to-Earn Blockchain Game",
    metaDescription: "DeFi Kingdoms is a blockchain-based game that combines DeFi protocols with fantasy RPG elements. Players can collect Hero NFTs, engage in quests, and earn rewards through gameplay.",
    description: "DeFi Kingdoms is a blockchain-based game that merges decentralized finance (DeFi) protocols with fantasy role-playing game (RPG) elements. Players can collect unique Hero NFTs, participate in quests, and earn rewards through various in-game activities.",
    details: `
        <p>DeFi Kingdoms is a unique play-to-earn blockchain game that integrates DeFi protocols with a fantasy RPG setting. Built on the Harmony and Avalanche blockchains, it offers players true ownership of in-game assets, each represented as unique NFTs.</p>
        
        <h2>About DeFi Kingdoms</h2>
        <p>In DeFi Kingdoms, players immerse themselves in a pixel-art fantasy world where they can collect and trade Hero NFTs, engage in quests, and participate in various DeFi activities such as liquidity pooling and staking. The game features a dynamic economy driven by its native tokens, JEWEL and CRYSTAL, which are used for in-game transactions and governance.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Hero NFTs:</strong> Collect and trade unique Hero NFTs, each with distinct attributes and abilities.</li>
            <li><strong>Quests and Adventures:</strong> Send your Heroes on quests to earn rewards, gather resources, and gain experience.</li>
            <li><strong>DeFi Integration:</strong> Participate in DeFi activities like liquidity pooling, staking, and farming to earn additional rewards.</li>
            <li><strong>Play-to-Earn Rewards:</strong> Earn JEWEL and CRYSTAL tokens through gameplay, quests, and DeFi activities.</li>
            <li><strong>Player-Driven Economy:</strong> Engage in a vibrant economy where players can buy, sell, and trade assets in a decentralized marketplace.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>DeFi Kingdoms has developed a loyal and active community. With regular updates, events, and expansions, the game continues to evolve, offering players new ways to engage and earn in its unique DeFi and RPG fusion.</p>
        
        <p>For more information or to start playing, visit the official <a href="https://defikingdoms.com" target="_blank" rel="noopener noreferrer">DeFi Kingdoms website</a>.</p>
    `,
    img: "/images/defi-kingdoms.png"
},
"pixels": {
    name: "Pixels",
    metaTitle: "Pixels | Play-to-Earn Blockchain Game",
    metaDescription: "Pixels is a blockchain-based open-world game where players can farm, explore, and create within a pixelated universe. Earn rewards through gameplay and trading.",
    description: "Pixels is a blockchain-based open-world game that allows players to engage in farming, exploration, and creation within a pixelated universe. It offers a play-to-earn model where players can earn rewards through various in-game activities.",
    details: `
        <p>Pixels is a captivating play-to-earn blockchain game that combines farming, exploration, and creation within a pixelated open-world environment. Built on the Ronin Network, Pixels provides players with true ownership of in-game assets, each represented as unique NFTs.</p>
        
        <h2>About Pixels</h2>
        <p>In Pixels, players immerse themselves in a vibrant universe where they can manage farms, explore diverse landscapes, and create unique structures. The game emphasizes community collaboration and offers a dynamic economy driven by its native token, PIXEL, which is used for in-game transactions and governance.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Farming and Resource Management:</strong> Cultivate crops, raise animals, and manage resources to build and expand your farm.</li>
            <li><strong>Exploration:</strong> Traverse a vast pixelated world filled with diverse biomes, hidden treasures, and challenges.</li>
            <li><strong>Creation and Customization:</strong> Design and construct unique structures, personalize your land, and showcase your creativity.</li>
            <li><strong>Play-to-Earn Rewards:</strong> Earn PIXEL tokens through gameplay, quests, and trading activities.</li>
            <li><strong>Community Collaboration:</strong> Engage with other players, participate in events, and contribute to a thriving in-game economy.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>Pixels has cultivated a dedicated and active community. The development team actively collaborates with players, incorporating feedback to enhance the gaming experience. Regular updates, events, and expansions keep the gameplay fresh and exciting, attracting a growing number of players and investors to the ecosystem.</p>
        
        <p>For more information or to start playing, visit the official <a href="https://www.pixels.xyz" target="_blank" rel="noopener noreferrer">Pixels website</a>.</p>
    `,
    img: "/images/pixels.png"
},
"wildforest": {
    name: "Wild Forest",
    metaTitle: "Wild Forest | Play-to-Earn Blockchain Game",
    metaDescription: "Wild Forest is a blockchain-based real-time strategy game where players build decks, engage in fast-paced PvP battles, and earn NFT rewards through gameplay.",
    description: "Wild Forest is a blockchain-based real-time strategy game that allows players to build powerful card decks, engage in intense PvP battles, and earn NFT rewards. It offers a play-to-earn model where players can earn rewards through various in-game activities.",
    details: `
        <p>Wild Forest is an innovative play-to-earn blockchain game that combines real-time strategy with card-collecting elements. Built on the Ronin Network, Wild Forest provides players with true ownership of in-game assets, each represented as unique NFTs.</p>
        
        <h2>About Wild Forest</h2>
        <p>In Wild Forest, players engage in fast-paced PvP battles on a single-screen battlefield. By building and customizing their decks with various unit cards, players can deploy units, capture territories, and strategize to destroy their opponent's base. The game emphasizes skill-based gameplay, reducing opportunities for unfair play and pay-to-win scenarios.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Real-Time Strategy Gameplay:</strong> Engage in dynamic PvP battles that require quick thinking and strategic planning.</li>
            <li><strong>Card Collecting and Deck Building:</strong> Collect, merge, and upgrade NFT unit cards to build powerful decks and develop winning strategies.</li>
            <li><strong>Play-to-Earn Rewards:</strong> Earn NFT rewards with real value by participating in battles and completing in-game tasks.</li>
            <li><strong>Marketplace Integration:</strong> Trade Wild Forest NFTs on Mavis Market, allowing for a player-driven economy.</li>
            <li><strong>Day-Night Cycle and Territory Control:</strong> Utilize the day-night cycle for tactical advantages and capture territories to gain control of the battlefield.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>Wild Forest has cultivated a dedicated and active community. The development team actively collaborates with players, incorporating feedback to enhance the gaming experience. Regular updates, events, and expansions keep the gameplay fresh and exciting, attracting a growing number of players and investors to the ecosystem.</p>
        
        <p>For more information or to start playing, visit the official <a href="https://playwildforest.io/" target="_blank" rel="noopener noreferrer">Wild Forest website</a>.</p>
    `,
    img: "/images/wild-forest.png"
},
"nightcrows": {
    name: "NIGHT CROWS",
    metaTitle: "NIGHT CROWS | Play-to-Earn MMORPG",
    metaDescription: "NIGHT CROWS is a blockchain-based MMORPG set in 13th-century Europe, offering large-scale battles, glider-based aerial combat, and a play-to-earn economy.",
    description: "NIGHT CROWS is a blockchain-based MMORPG that immerses players in a 13th-century European setting, featuring large-scale battles, glider-based aerial combat, and a play-to-earn economy.",
    details: `
        <p>NIGHT CROWS is an innovative play-to-earn MMORPG developed by Madngine and published by Wemade. Utilizing Unreal Engine 5, the game delivers a realistic 13th-century European environment where magic exists, and players can engage in massive battles both on the ground and in the air using gliders.</p>
        
        <h2>About NIGHT CROWS</h2>
        <p>Set in a medieval European backdrop, NIGHT CROWS offers a dynamic world where players can choose from various classes, participate in large-scale PvP battles, and explore a richly detailed environment. The game integrates blockchain technology, allowing players to own in-game assets and earn rewards through gameplay.</p>
        
        <h2>Main Features</h2>
        <ul>
            <li><strong>Massive PvP Battles:</strong> Engage in large-scale battles with over a thousand players, utilizing inter-server technology for expansive warfare.</li>
            <li><strong>Glider-Based Aerial Combat:</strong> Use gliders to navigate the skies, adding a vertical dimension to combat and exploration.</li>
            <li><strong>Play-to-Earn Economy:</strong> Earn tokens and in-game assets through various activities, participating in a player-driven economy.</li>
            <li><strong>Class Diversity:</strong> Choose from multiple classes, each with unique abilities suited to different combat styles.</li>
        </ul>
        
        <h2>Community and Growth</h2>
        <p>NIGHT CROWS has cultivated an active community and continues to grow through regular updates and events. The game’s realistic graphics and dynamic battles have attracted a global player base, enhancing the overall play-to-earn MMORPG experience.</p>
        
        <p>For more information or to start playing, visit the official <a href="https://nightcrows.com" target="_blank" rel="noopener noreferrer">NIGHT CROWS website</a>.</p>
    `,
    img: "/images/night-crows.png"
}


    // További játékok hasonló szerkezetben...
};

function P2EDetailPage() {
    const { game } = useParams();
    const gameDetail = gameDetails[game];

    if (!gameDetail) {
        return <p>Game not found.</p>;
    }

    return (
        <div className={styles.detailContainer}>
            <Helmet>
                <title>{gameDetail.metaTitle}</title>
                <meta name="description" content={gameDetail.metaDescription} />
                <meta name="keywords" content={`${gameDetail.name}, P2E game, play-to-earn, blockchain gaming, NFT game`} />
                <link rel="canonical" href={`https://www.jsoroko.com/library/p2e-games/${game}`} />
            </Helmet>
            <img src={gameDetail.img} alt={`${gameDetail.name} Logo`} className={styles.detailImage} loading="lazy" />
            <h1>{gameDetail.name}</h1>
            <p className={styles.detailDescription}>{gameDetail.description}</p>
            <div
                className={styles.detailContent}
                dangerouslySetInnerHTML={{ __html: gameDetail.details }}
            />
        </div>
    );
}

export default P2EDetailPage;
