import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './ApiCategoryList.module.css';
import { Helmet } from 'react-helmet';

const apiCategories = [
    { name: "Exchange API", link: "exchange" },
    { name: "Data API", link: "data" },
    { name: "Node API", link: "node" },
    { name: "Payment API", link: "payment" },
    { name: "Wallet API", link: "wallet" },
    { name: "Blockchain Explorer API", link: "explorer" }
];

function ApiCategoryList() {
    const location = useLocation();
    const canonicalUrl = `https://jsoroko.com${location.pathname}`;
    return (
        <div className={styles.libraryContainer}>
              <Helmet>
                <title>Cryptocurrency API Categories | Explore Various Crypto APIs</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta name="description" content="Discover a wide range of cryptocurrency API categories including Exchange, Data, Node, Payment, Wallet, and Blockchain Explorer APIs. Perfect for developers and crypto enthusiasts looking for resources." />
                <meta name="keywords" content="cryptocurrency APIs, exchange APIs, data APIs, blockchain explorer, wallet APIs, node APIs, crypto payment APIs" />
            </Helmet>
            <header>
            <h2>API Categories</h2>
    <p>Explore a wide range of API categories tailored to meet various development needs within the cryptocurrency and blockchain space. Our selection includes APIs for market data, blockchain infrastructure, trading platforms, NFT management, and more. Each category provides specialized APIs designed to streamline and enhance your projects, whether you’re building trading bots, decentralized applications, or analytic tools.</p>
    <p>Choose an API category below to discover the most relevant APIs for your project. From real-time data and transaction services to smart contract interaction and Web3 tools, our library has the resources to support all levels of crypto development.</p>
            </header>
            <section className={styles.categoryList}>
                {apiCategories.map((category, index) => (
                    <Link
                        key={index}
                        to={`/library/api/${category.link}`}
                        className={styles.categoryCard}
                    >
                        <h2>{category.name}</h2>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default ApiCategoryList;
