import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Wallet.module.css';

const walletData = [
    {
        name: "MetaMask",
        description: "A popular Ethereum wallet that enables users to store and manage their assets and interact with decentralized applications.",
        link: "metamask",
        img: "/images/metamask.png"
    },
    {
        name: "Phantom",
        description: "Phantom is a user-friendly and secure Solana-compatible wallet that enables users to manage their digital assets and interact with decentralized applications within the Solana ecosystem.",
        link: "phantom",
        img: "/images/phantom.png"
    },
    {
        name: "Ronin",
        description: "Ronin Wallet is a user-friendly, secure wallet designed specifically for the Ronin blockchain, enabling Axie Infinity players to manage their assets, including AXS and SLP, with low transaction fees.",
        link: "ronin",
        img: "/images/ronin.png"
    },
    {
        name: "Coinbase Wallet",
        description: "Coinbase Wallet is a user-friendly, secure wallet for managing multiple cryptocurrencies and accessing decentralized applications directly from your browser or mobile device.",
        link: "coinbase",
        img: "/images/coinbase_wallet.png"
    },
    {
        name: "Trust Wallet",
        description: "Trust Wallet is a secure and versatile multi-crypto wallet that allows users to manage their digital assets and access decentralized applications directly from their mobile devices.",
        link: "trust",
        img: "/images/trust.png"
    },
    {
        name: "Rainbow Wallet",
        description: "Rainbow Wallet is a secure, Ethereum-focused wallet designed with a fun and intuitive interface, making it easy for users to manage their digital assets and explore the Ethereum ecosystem.",
        link: "rainbow",
        img: "/images/rainbow.png"
    },
    {
        name: "WalletConnect",
        description: "WalletConnect is an open-source protocol that securely connects crypto wallets to decentralized applications, providing a bridge for seamless transactions and dApp interactions across various blockchain networks.",
        link: "walletconnect",
        img: "/images/walletconnect.png"
    },
    {
        name: "OKX Wallet",
        description: "OKX Wallet is a versatile, multi-chain wallet offering users secure storage, staking, and access to DeFi applications on various blockchain networks, including Ethereum, BNB Chain, and OKC.",
        link: "okx",
        img: "/images/okx_wallet.png"
    },
    {
        name: "Bitget Wallet",
        description: "Bitget Wallet is a multi-chain crypto wallet providing secure storage, staking opportunities, and access to decentralized finance applications across various blockchain networks like Ethereum and BNB Chain.",
        link: "bitget",
        img: "/images/bitget_wallet.png"
    },
    {
        name: "Binance Web3 Wallet",
        description: "Binance Web3 Wallet is a versatile, secure wallet designed to support multiple blockchains, enabling users to manage assets, access DeFi applications, and participate in Web3 activities directly within the Binance ecosystem.",
        link: "binance_web3",
        img: "/images/binance_web3_wallet.png"
    },
    {
        name: "SafePal Wallet",
        description: "SafePal Wallet is a secure multi-chain crypto wallet that combines both hardware and software options, allowing users to manage assets and interact with decentralized applications across various blockchain networks.",
        link: "safepal",
        img: "/images/safepal.png"
    },
    {
        name: "Uniswap Wallet",
        description: "Uniswap Wallet is a DeFi wallet that offers secure asset management and direct access to the Uniswap decentralized exchange and Ethereum-based decentralized applications, making it a preferred choice for DeFi enthusiasts.",
        link: "uniswap_wallet",
        img: "/images/uniswap_wallet.png"
    },
    {
        name: "Magic Eden Wallet",
        description: "Magic Eden Wallet is an NFT-focused wallet offering secure asset management and direct access to the Magic Eden marketplace and Ethereum and Solana-based decentralized applications, making it a popular choice for NFT collectors.",
        link: "magic_eden",
        img: "/images/magic_eden.png"
    },
    {
        name: "Zerion Wallet",
        description: "Zerion Wallet is a DeFi-focused wallet that offers secure management of Ethereum and multi-chain assets, portfolio tracking, and direct access to decentralized applications on Ethereum and beyond.",
        link: "zerion_wallet",
        img: "/images/zerion_wallet.png"
    },
    {
        name: "Kraken Wallet",
        description: "Kraken Wallet is a secure wallet solution offered by Kraken Exchange, enabling users to manage, store, and stake various cryptocurrencies within a trusted environment.",
        link: "kraken_wallet",
        img: "/images/kraken_wallet.png"
    },
    // Add additional wallets here as needed
];

function Wallet() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
            <title>Crypto Wallets | Securely Manage, Stake, and Access dApps for Your Digital Assets</title>
            <meta name="description" content="Explore a curated selection of top crypto wallets designed to securely store, manage, stake, and interact with digital assets across multiple blockchain networks, including Ethereum, Solana, and Binance Smart Chain." />
            <meta name="keywords" content="crypto wallets, digital wallets, blockchain wallets, Ethereum wallets, Solana wallets, secure crypto storage, DeFi wallets, NFT wallets, staking wallets, multi-chain wallets" />

                <link rel="canonical" href="https://www.jsoroko.com/library/wallets" />
            </Helmet>

            <header>
                <h1>Crypto Wallets</h1>
                <p>Discover a comprehensive selection of the leading wallets for securely storing, managing, and staking your cryptocurrency assets. Access decentralized applications (dApps) across multiple blockchain networks, including Ethereum, Solana, and Binance Smart Chain. Our curated list includes multi-chain and NFT-focused wallets, DeFi-compatible wallets with portfolio tracking, and highly secure options, empowering you to interact seamlessly with the evolving world of decentralized finance.</p>
            </header>

            <section className={styles.walletList}>
                {walletData.map((wallet, index) => (
                    <Link to={`/library/wallets/${wallet.link}`} key={index} className={styles.walletCard}>
                        <img src={wallet.img} alt={`${wallet.name} Logo`} className={styles.walletImage} loading="lazy" />
                        <h2>{wallet.name}</h2>
                        <p>{wallet.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default Wallet;
