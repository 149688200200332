import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './P2EGames.module.css';

const p2eGamesData = [
    {
        name: "Axie Infinity",
        description: "A popular blockchain game where players can earn tokens by battling, breeding, and trading Axies.",
        link: "axie-infinity",
        img: "/images/axie-infinity.png"
    },
    {
        name: "The Sandbox",
        description: "A virtual world where players can build, own, and monetize their gaming experiences on the Ethereum blockchain.",
        link: "the-sandbox",
        img: "/images/the-sandbox.png"
    },
    {
        name: "Gods Unchained",
        description: "A competitive trading card game where players can earn and own their cards, thanks to blockchain technology.",
        link: "gods-unchained",
        img: "/images/gods-unchained.png"
    },
    {
        name: "Decentraland",
        description: "Decentraland is a decentralized virtual world that enables users to own, create, and trade digital assets. ",
        link: "decentraland",
        img: "/images/decentraland.png"
    },
    {
        name: "Splinterlands",
        description: "Splinterlands is a popular blockchain-based digital card game that allows players to collect, trade, and battle with unique NFT cards. ",
        link: "splinterlands",
        img: "/images/splinterlands.png"
    },
    {
        name: "Illuvium",
        description: "Illuvium is a blockchain-based open-world RPG that allows players to explore diverse landscapes, capture creatures known as Illuvials, and participate in strategic auto-battler combat. It offers a play-to-earn model where players can earn rewards through various in-game activities.",
        img: "/images/illuvium.png",
        link: "illuvium"

    },
    {
        name: "Defi Kingdoms",
        description: "DeFi Kingdoms is a blockchain-based game that merges decentralized finance (DeFi) protocols with fantasy role-playing game (RPG) elements. Players can collect unique Hero NFTs, participate in quests, and earn rewards through various in-game activities.",
        img: "/images/defi-kingdoms.png",
        link: "defikingdoms"

    },
    {
        name: "Pixels",
        description: "Pixels is a blockchain-based open-world game that allows players to engage in farming, exploration, and creation within a pixelated universe. It offers a play-to-earn model where players can earn rewards through various in-game activities.",
        img: "/images/pixels.png",
        link: "pixels"

    },
    {
        name: "Wild Forest",
        description: "Wild Forest is a blockchain-based real-time strategy game that allows players to build powerful card decks, engage in intense PvP battles, and earn NFT rewards. It offers a play-to-earn model where players can earn rewards through various in-game activities.",
        img: "/images/wild-forest.png",
        link: "wildforest"

    },
    {
        name: "NIGHT CROWS",
        description: "NIGHT CROWS is a blockchain-based MMORPG that immerses players in a 13th-century European setting, featuring large-scale battles, glider-based aerial combat, and a play-to-earn economy.",
        img: "/images/night-crows.png",
        link: "nightcrows"

    }
    // Add more P2E games as needed
];

function P2EGames() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>P2E Games | Play-to-Earn Crypto Gaming Platforms</title>
                <meta name="description" content="Discover popular P2E games that allow players to earn cryptocurrency by playing, trading, and interacting in virtual worlds." />
                <meta name="keywords" content="P2E games, play-to-earn, crypto games, blockchain gaming, NFT games" />
                <link rel="canonical" href="https://www.jsoroko.com/library/p2e-games" />
            </Helmet>

            <header>
                <h1>P2E Games</h1>
                <p>Explore top Play-to-Earn (P2E) games where players can earn cryptocurrency by engaging in various in-game activities, battling, trading, and more.</p>
            </header>

            <section className={styles.gamesList}>
                {p2eGamesData.map((game, index) => (
                    <Link to={`/library/p2e-games/${game.link}`} key={index} className={styles.gameCard}>
                        <img src={game.img} alt={`${game.name} Logo`} className={styles.gameImage} loading="lazy" />
                        <h2>{game.name}</h2>
                        <p>{game.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default P2EGames;
