import React, { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import styles from './ApiList.module.css';
import { Helmet } from 'react-helmet';


const apiData = [
    { 
        title: "Binance API", 
        description: "Comprehensive trading and market data from Binance exchange.", 
        category: "exchange", 
        link: "binance", 
        img: "/images/binance.png", 
        details: `

        `
    },
    {
        title: "Coinbase API", 
        description: "Access Coinbase exchange data and trading functionality.", 
        category: "exchange", 
        link: "coinbase", 
        img: "/images/coinbase.png",
    },
    { 
        title: "Crypto.com API", 
        description: "Access Crypto.com's trading and financial data for seamless integration with crypto markets.", 
        category: "exchange", 
        link: "crypto-com", 
        img: "/images/crypto-com.png", 
        details: `
            
        `
    },
    { 
        title: "Bybit API", 
        description: "Utilize Bybit's comprehensive trading tools and real-time market data for efficient integration with cryptocurrency trading environments.", 
        category: "exchange", 
        link: "bybit", 
        img: "/images/bybit.png", 
        details: `
            
        `
    },
    { 
        title: "Infura API", 
        description: "Access Ethereum and IPFS networks with Infura's reliable, secure API.", 
        category: "node", 
        link: "infura", 
        img: "/images/infura.png", 
        details: `
            
        `
        
    },
    { 
        title: "Alchemy API",
        description: "Powerful blockchain development platform and Ethereum API.",
        category: "node",
        link: "alchemy",
        img: "/images/alchemy.png",
        details: `
            
        `
        
    },
    { 
        title: "GetBlock API",
        description: "Fast and easy access to blockchain nodes for developers.",
        category: "node",
        link: "getblock",
        img: "/images/getblock.png",
        details: `
            
        `
        
    },
    { 
        title: "QuickNode API",
        description: "Accelerate dApp development with high-performance blockchain infrastructure.",
        category: "node",
        link: "quicknode",
        img: "/images/quicknode.png",
        details: `
            
        `
        
    },
    { 
        title: "Chainstack API",
        description: "Enterprise-grade blockchain infrastructure for multiple protocols.",
        category: "node",
        link: "chainstack",
        img: "/images/chainstack.png",
        details: `
            
        `
        
    },
    { 
        title: "Moralis API",
        description: "Comprehensive Web3 development platform with multi-chain support.",
        category: "node",
        link: "moralis",
        img: "/images/moralis.png",
        details: `
            
        `
        
    },
    { 
        title: "LlamaNodes API",
        description: "Reliable and scalable blockchain node infrastructure for Web3 developers.",
        category: "node",
        link: "llamanodes",
        img: "/images/llamanodes.png",
        details: `
            
        `
        
    },
    { 
        title: "Ankr API",
        description: "Decentralized node infrastructure and multi-chain API for Web3 developers.",
        category: "node",
        link: "ankr",
        img: "/images/ankr.png",
        details: `
            
        `
        
    },
    { 
        title: "Helius API",
        description: "Developer-friendly API for Solana blockchain data and analytics.",
        category: "node",
        link: "helius",
        img: "/images/helius.png",
        details: `
            
        `
        
    },
    { 
        title: "Shyft API",
        description: "Reliable and optimized RPC API for Solana developers, designed for high uptime and speed.",
        category: "node",
        link: "shyft",
        img: "/images/shyft.png",
        details: `
            
        `
        
    },

    // ezek voltak az node apik
    { 
        title: "Vezgo API",
        description: "Comprehensive API for accessing digital asset data and Web3 accounts across wallets and exchanges.",
        category: "wallet",
        link: "vezgo",
        img: "/images/vezgo.png",
        details: `
            
        `
        
    },
    { 
        title: "Moralis Wallet API",
        description: "Developer-friendly API for integrating Web3 wallet functionalities across multiple blockchains.",
        category: "wallet",
        link: "moralis-wallet-api",
        img: "/images/moralis.png",
        details: `
            
        `
        
    },
    { 
        title: "Coinbase Wallet SDK",
        description: "Open-source SDK enabling seamless integration of dApps with Coinbase Wallet across multiple platforms.",
        category: "wallet",
        link: "coinbase-wallet-sdk",
        img: "/images/coinbase.png",
        details: `
            
        `
        
    },
    { 
        title: "Crypto APIs Wallet as a Service",
        description: "Secure and scalable Wallet-as-a-Service (WaaS) API for cryptocurrency applications.",
        category: "wallet",
        link: "cryptoapis-wallet-api",
        img: "/images/cryptoapis.png",
        details: `
            
        `
        
    },
    { 
        title: "Coinbase Commerce API",
        description: "Enable cryptocurrency payments for online businesses through a secure, non-custodial platform.",
        category: "payment",
        link: "coinbase-commerce-api",
        img: "/images/coinbase.png",
        details: `
            
        `
        
    },
    { 
        title: "BitPay API",
        description: "A comprehensive API for integrating cryptocurrency payments into e-commerce platforms.",
        category: "payment",
        link: "bitpay-api",
        img: "/images/bitpay.png",
        details: `
            
        `
        
    },
    { 
        title: "NOWPayments API",
        description: "Flexible, non-custodial payment API for accepting over 300 cryptocurrencies.",
        category: "payment",
        link: "nowpayments-api",
        img: "/images/nowpayments.png",
        details: `
            
        `
        
    },
    { 
        title: "CoinGate API",
        description: "Versatile payment API for accepting over 50 cryptocurrencies with seamless fiat conversion.",
        category: "payment",
        link: "coingate-api",
        img: "/images/coingate.png",
        details: `
            
        `
        
    },
    { 
        title: "Binance Pay API",
        description: "Secure and flexible payment API for accepting cryptocurrency with Binance’s ecosystem.",
        category: "payment",
        link: "binance-pay-api",
        img: "/images/binance.png",
        details: `
            
        `
        
    },
    //itt er veget a payment
    { 
        title: "CoinGecko API",
        description: "Comprehensive data API providing real-time and historical cryptocurrency market information.",
        category: "data",
        link: "coingecko-api",
        img: "/images/coingecko.png",
        details: `
            
        `
        
    },
    { 
        title: "CoinMarketCap API",
        description: "Powerful API providing real-time and historical cryptocurrency market data and insights.",
        category: "data",
        link: "coinmarketcap-api",
        img: "/images/coinmarketcap.png",
        details: `
            
        `
        
    },
    { 
        title: "CryptoCompare API",
        description: "Comprehensive API offering real-time and historical cryptocurrency market data, social insights, and blockchain analytics.",
        category: "data",
        link: "cryptocompare-api",
        img: "/images/cryptocompare.png",
        details: `
            
        `
        
    },
    { 
        title: "Messari API",
        description: "Comprehensive API providing quantitative and qualitative cryptocurrency data for market analysis and research.",
        category: "data",
        link: "messari-api",
        img: "/images/messari.png",
        details: `
            
        `
        
    },
    { 
        title: "Glassnode API",
        description: "Advanced on-chain and financial market data API for comprehensive blockchain analysis.",
        category: "data",
        link: "glassnode-api",
        img: "/images/glassnode.png",
        details: `
            
        `
        
    },
    { 
        title: "Santiment API",
        description: "Comprehensive API providing on-chain, social, developer activity, and pricing data for over 2,000 crypto assets.",
        category: "data",
        link: "santiment-api",
        img: "/images/santiment.png",
        details: `
            
        `
        
    },
    { 
        title: "IntoTheBlock API",
        description: "Comprehensive API providing on-chain analytics, DeFi insights, and market indicators for cryptocurrencies.",
        category: "data",
        link: "intotheblock-api",
        img: "/images/intotheblock.png",
        details: `
            
        `
        
    },
    //itt er veget a data
    { 
        title: "Etherscan API",
        description: "Comprehensive API for accessing Ethereum blockchain data, including transactions, balances, and smart contract information.",
        category: "explorer",
        link: "etherscan-api",
        img: "/images/etherscan.png",
        details: `
            
        `
        
    },
    { 
        title: "BscScan API",
        description: "Comprehensive API for accessing Binance Smart Chain data, including transactions, account balances, and smart contract information.",
        category: "explorer",
        link: "bscscan-api",
        img: "/images/bscscan.png",
        details: `
            
        `
        
    },
    { 
        title: "Blockchain.com API",
        description: "Comprehensive API suite providing access to blockchain data, market data, and payment processing services.",
        category: "explorer",
        link: "blockchain-com-api",
        img: "/images/blockchain-com.png",
        details: `
            
        `
        
    },
    { 
        title: "Blockchair API",
        description: "Comprehensive API providing access to data from multiple blockchains, including Bitcoin, Ethereum, and more.",
        category: "explorer",
        link: "blockchair-api",
        img: "/images/blockchair.png",
        details: `
            
        `
        
    },
    { 
        title: "Solscan API",
        description: "Comprehensive API providing access to Solana blockchain data, including transactions, accounts, and token information.",
        category: "explorer",
        link: "solscan-api",
        img: "/images/solscan.png",
        details: `
            
        `
        
    },


];

const categoryMeta = {
    exchange: {
        title: "Exchange APIs | Cryptocurrency Trading and Market Data",
        description: "Explore cryptocurrency exchange APIs that provide access to trading, market data, and more.",
        keywords: "exchange APIs, crypto trading, market data, cryptocurrency exchanges"
    },
    data: {
        title: "Data APIs | Market Data and Blockchain Analytics",
        description: "Access real-time and historical cryptocurrency market data and blockchain analytics APIs.",
        keywords: "data APIs, crypto market data, blockchain analytics, cryptocurrency insights"
    },
    node: {
        title: "Node APIs | Blockchain Infrastructure Access",
        description: "Find APIs that offer blockchain node access and infrastructure services.",
        keywords: "node APIs, blockchain access, crypto infrastructure, blockchain node services"
    },
    payment: {
        title: "Payment APIs | Cryptocurrency Payment Solutions",
        description: "Discover APIs that facilitate cryptocurrency payments and digital transactions.",
        keywords: "payment APIs, crypto payments, blockchain transactions, digital currency"
    },
    wallet: {
        title: "Wallet APIs | Wallet Management and Transactions",
        description: "APIs for managing cryptocurrency wallets, checking balances, and processing transactions.",
        keywords: "wallet APIs, crypto wallet management, cryptocurrency transactions, blockchain wallets"
    },
    explorer: {
        title: "Blockchain Explorer APIs | Transaction and Address Data",
        description: "Access transaction, block, and address data with blockchain explorer APIs.",
        keywords: "blockchain explorer APIs, transaction data, blockchain addresses, crypto analytics"
    }
};

const itemsPerPage = 25;

function ApiList() {
    const { category } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();
    const canonicalUrl = `https://jsoroko.com${location.pathname}`;
    // Meta information for the selected category
    const meta = categoryMeta[category] || {
        title: "API Library | Explore Cryptocurrency APIs by Category",
        description: "Browse a wide range of cryptocurrency APIs, categorized for easy access to market data, exchange functionalities, blockchain infrastructure, and more.",
        keywords: "crypto APIs, cryptocurrency tools, blockchain data, crypto exchange APIs, crypto wallet APIs"
    };

    // Filter and paginate the APIs for the current category
    const filteredAPIs = apiData.filter(api => api.category === category);
    const totalPages = Math.ceil(filteredAPIs.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentAPIs = filteredAPIs.slice(startIndex, startIndex + itemsPerPage);

    const handlePrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

    return (
        <div className={styles.libraryContainer}>
            <Helmet>
                <title>{meta.title}</title>
                <link rel="canonical" href={canonicalUrl} />

                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
            </Helmet>
            <header>
                <h1>{category.charAt(0).toUpperCase() + category.slice(1).replace('-', ' ')} APIs</h1>
                <p>{meta.description}</p>
            </header>
            <section className={styles.apiList}>
                {currentAPIs.map((api, index) => (
                    <Link key={index} to={`/library/api/${category}/${api.link}`} className={styles.apiCard}>
                        {currentPage === 1 && (
                            <img src={api.img} alt={`${api.title} Logo`} className={styles.apiImage} loading="lazy" />
                        )}
                        <h2>{api.title}</h2>
                        <p>{api.description}</p>
                    </Link>
                ))}
            </section>
            <div className={styles.pagination}>
                <button onClick={handlePrevPage} disabled={currentPage === 1} className={styles.paginationButton}>Prev</button>
                <button onClick={handleNextPage} disabled={currentPage === totalPages} className={styles.paginationButton}>Next</button>
            </div>
        </div>
    );
}

export default ApiList;