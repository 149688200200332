import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Blockchains.module.css';

const blockchainsData = [
    {
        name: "Ethereum",
        description: "Ethereum is a decentralized platform that enables smart contracts and decentralized applications (DApps) to run without any downtime, fraud, or interference from a third party.",
        link: "ethereum",
        img: "/images/ethereum.png"
    },
    {
        name: "BSC",
        description: "Binance Smart Chain is a fast and secure blockchain that provides a high-performance environment for decentralized applications and DeFi projects.",
        link: "bsc",
        img: "/images/bnb-smart-chain.png"
    },
    {
        name: "Polygon",
        description: "Polygon is a leading blockchain platform that enhances Ethereum's capabilities by providing Layer 2 scaling solutions, facilitating faster and more affordable transactions for DApps and users.",
        link: "polygon",
        img: "/images/polygon.png"
    },
    {
        name: "Solana",
        description: "Solana is a leading blockchain platform known for its high throughput and low latency, facilitating the development of scalable decentralized applications (DApps) and cryptocurrencies.",
        link: "solana",
        img: "/images/solana.png"
    },
    {
        name: "TRON",
        description: "TRON is a blockchain-based platform aiming to decentralize the internet by allowing content creators to interact directly with consumers, eliminating intermediaries and fostering a more transparent and efficient digital content ecosystem.",
        link: "tron",
        img: "/images/tron.png"
    },
    {
        name: "Avalanche",
        description: "Avalanche is a blockchain platform known for its high throughput, low latency, and scalability, enabling the development of decentralized applications (DApps) and financial solutions across industries.",
        link: "avalanche",
        img: "/images/avalanche.png"
    },
    {
        name: "Arbitrum",
        description: "Arbitrum is a leading Layer 2 scaling solution for Ethereum, designed to improve transaction speeds and lower costs, facilitating the development of efficient decentralized applications (DApps) and smart contracts.",
        link: "arbitrum",
        img: "/images/arbitrum.png"
    },
    {
        name: "Base",
        description: "Base is a Layer 2 (L2) blockchain platform built on Ethereum, developed by Coinbase to provide a secure, low-cost, and developer-friendly environment for building decentralized applications (DApps) and bringing the next billion users on-chain.",
        link: "base",
        img: "/images/base.png"
    },
    {
        name: "Fantom",
        description: "Fantom is a high-performance, scalable, and secure smart-contract platform designed to overcome the limitations of previous blockchain platforms, providing fast, low-cost transactions for decentralized applications (DApps) and digital assets.",
        link: "fantom",
        img: "/images/fantom.png"
    },
    {
        name: "Wax",
        description: "WAX (Worldwide Asset eXchange) is a purpose-built blockchain platform known for its focus on NFTs and decentralized applications (DApps), providing a carbon-neutral, high-performance environment for digital asset creation and trading.",
        link: "wax",
        img: "/images/wax.png"
    },
    {
        name: "Tezos",
        description: "Tezos is an open-source blockchain platform designed to evolve by upgrading itself, facilitating the development of smart contracts and decentralized applications (DApps) with an emphasis on security, governance, and sustainability.",
        link: "tezos",
        img: "/images/Tezos.png"
    },
    {
        name: "EOS",
        description: "EOS is an open-source blockchain platform that prioritizes high performance, flexibility, security, and developer freedom, enabling the creation and deployment of scalable decentralized applications (DApps) with minimal latency and transaction fees.",
        link: "eos",
        img: "/images/eos.png"
    },
    {
        name: "Cronos",
        description: "Cronos is an open-source blockchain platform designed to bridge the Ethereum and Cosmos ecosystems, facilitating the development of scalable and low-cost decentralized applications (DApps) and digital assets.",
        link: "cronos",
        img: "/images/cronos.png"
    },
    {
        name: "Blast",
        description: "Blast is an EVM-compatible, optimistic rollup Layer 2 blockchain that provides native yield on ETH and stablecoins, aiming to improve scalability and user incentives in the Ethereum ecosystem.",
        link: "blast",
        img: "/images/blast.png"
    },
    {
        name: "Ton",
        description: "The Open Network (TON) is a decentralized blockchain platform focused on scalability, security, and integration with Telegram, facilitating the development of diverse decentralized applications (DApps) and services.",
        link: "ton",
        img: "/images/ton.png"
    },
    {
        name: "Linea",
        description: "Linea is an EVM-equivalent zkEVM rollup network developed by Consensys, designed to scale Ethereum by providing a high-performance environment for decentralized applications (DApps) with reduced transaction costs.",
        link: "linea",
        img: "/images/linea.png"
    },
    {
        name: "Aurora",
        description: "Aurora is an Ethereum-compatible blockchain platform operating on the NEAR Protocol, designed to provide high-performance, scalable, and cost-effective solutions for decentralized applications (DApps).",
        link: "aurora",
        img: "/images/aurora.png"
    },
    {
        name: "Klaytn",
        description: "Klaytn is a public blockchain platform developed by Kakao, focusing on enterprise-grade applications and mass adoption, providing a user-friendly environment for decentralized applications (DApps).",
        link: "klaytn",
        img: "/images/Klaytn.png"
    },
    {
        name: "Ronin",
        description: "Ronin Network is a blockchain platform tailored for gaming and digital collectibles, providing a scalable and efficient environment with low transaction fees and seamless integration for developers and users.",
        link: "ronin",
        img: "/images/ronin.png"
    },
    {
        name: "Near",
        description: "NEAR Protocol is a decentralized, developer-friendly blockchain platform focused on scalability and usability, facilitating the seamless creation and deployment of decentralized applications (DApps).",
        link: "near",
        img: "/images/near.png"
    }
    // Add more blockchains as needed
];

function Blockchains() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>Blockchain Networks | Explore Different Blockchain Platforms</title>
                <meta name="description" content="Learn about various blockchain networks, their unique features, and how they contribute to the world of decentralized technology." />
                <meta name="keywords" content="blockchain networks, Ethereum, Binance Smart Chain, Polygon, blockchain platforms, decentralized networks" />
                <link rel="canonical" href="https://www.jsoroko.com/library/blockchains" />
            </Helmet>

            <header>
                <h1>Blockchain Networks</h1>
                <p>Explore the leading blockchain networks that power the decentralized world. Learn about each platform's unique features, applications, and innovations.</p>
            </header>

            <section className={styles.networksList}>
                {blockchainsData.map((blockchain, index) => (
                    <Link to={`/library/blockchains/${blockchain.link}`} key={index} className={styles.networkCard}>
                        <img src={blockchain.img} alt={`${blockchain.name} Logo`} className={styles.networkImage} loading="lazy" />
                        <h2>{blockchain.name}</h2>
                        <p>{blockchain.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default Blockchains;
