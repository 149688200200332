// fetchCategories.js
import sanityClient from './sanityClient';

const fetchCategories = async () => {
  try {
    const categoryData = await sanityClient.fetch(`*[_type == "category"] | order(publishedAt desc){
      title,
      slug,
      description,
      metaTitle,
      metaDescription,
      metaKeywords,
      publishedAt // használjuk a dátum szerinti rendezéshez
    }`);
    return categoryData;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
};

export default fetchCategories;
