import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';  // Accordionhoz szükséges

const FAQ = () => {
  return (
    <div className="faq-container" style={faqContainerStyle}>
      <h2>Frequently Asked Questions</h2>
      <div className="accordion" id="faqAccordion">
        
        {/* How to create a meme token */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              How to create a meme token?
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              You can create your own meme token by filling in the form with the token name, symbol, and total supply. Once you're done, click on 'Create Token' to finalize the process.
            </div>
          </div>
        </div>

        {/* Wallets */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Wallets
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Currently, the platform is only available with MetaMask wallet support. Make sure to connect your MetaMask wallet to interact with the platform and create your token.
            </div>
          </div>
        </div>

        {/* How long does it take to create a token */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              How long does it take to create a token?
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Creating a token usually takes around 1 minute, but this can vary depending on the network conditions.
            </div>
          </div>
        </div>
{/* What is a meme coin */}
<div className="accordion-item">
  <h2 className="accordion-header" id="headingFive">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      What is a meme coin?
    </button>
  </h2>
  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
    <div className="accordion-body">
      A meme coin is a type of cryptocurrency that is often created as a parody or humorous take on other cryptocurrencies. These coins are typically inspired by internet memes and pop culture references.
    </div>
  </div>
</div>

        {/* Is MetaMask required */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              Is MetaMask required?
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              Yes, MetaMask is required to interact with the Base blockchain and create your token. Make sure to connect your wallet before starting the token creation process.
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

const faqContainerStyle = {
    position: 'fixed',
    top: '150px', // Módosított top érték, hogy lejjebb legyen
    right: '20px',
    width: '300px',
    zIndex: '1000',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    maxHeight: '90vh',
};

export default FAQ;
