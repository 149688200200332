import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import { Helmet } from 'react-helmet';
import CreateTokenABI from './CreateToken.json';
import { CREATE_TOKEN_ADDRESS } from './contractConfig';
import MetaMaskLogo from './meta-mask-logo.png';
import BaseLogo from './base-logo.png';
import FAQ from './FAQ';
import './BaseNetworkPage.css';
import Sidebar from './Sidebar';
import Footer from './Footer'; // Footer komponens importálása

function BaseNetworkPage() {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState(0);
  const [status, setStatus] = useState("");
  const [tokenImage, setTokenImage] = useState(null);
  const [imageHash, setImageHash] = useState("");
  const [account, setAccount] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const location = useLocation();
  const canonicalUrl = `https://jsoroko.com${location.pathname}`;

  const handleFileChange = (e) => {
    setTokenImage(e.target.files[0]);
  };

  const uploadImageToPinata = async (imageFile) => {
    const formData = new FormData();
    formData.append('file', imageFile);

    const metadata = JSON.stringify({ name: 'Token Logo' });
    formData.append('pinataMetadata', metadata);

    const response = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
      method: 'POST',
      headers: {
        'pinata_api_key': '509f5cca84c1eb02f26b',
        'pinata_secret_api_key': '3729381aad8ce3865da64620a44961e26aa2534f3595d867f2968211c084569e'
      },
      body: formData
    });

    const result = await response.json();
    return result.IpfsHash;
  };

  const handleCreateToken = async () => {
    if (!window.ethereum) {
      setStatus("MetaMask is required to continue.");
      return;
    }

    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const contract = new ethers.Contract(CREATE_TOKEN_ADDRESS, CreateTokenABI.abi, signer);

      const adjustedTotalSupply = ethers.utils.parseUnits(totalSupply.toString(), 18);

      let imageURI = "";
      let transactionFee = "0.00042";
      if (tokenImage) {
        setStatus("Uploading image...");
        const hash = await uploadImageToPinata(tokenImage);
        imageURI = `ipfs://${hash}`;
        transactionFee = "0.00084";
        setImageHash(hash);
      }

      const tx = await contract.createToken(name, symbol, adjustedTotalSupply, imageURI, {
        value: ethers.utils.parseEther(transactionFee),
      });

      setStatus("Transaction sent, awaiting confirmation...");
      await tx.wait();
      setStatus(`Token successfully created! ${imageHash ? `Image Hash: ${imageHash}` : ""}`);
    } catch (error) {
      setStatus(`An error occurred: ${error.message}`);
    }
  };

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
      } catch (error) {
        console.error("MetaMask connection failed", error);
      }
    } else {
      alert("Please install MetaMask!");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <Helmet>
        <title>Create Your Meme Token on the Base Network</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content="Easily launch your own Meme Token on the Base Network using our platform. Connect your wallet, customize your token, and bring your ideas to life." />
        <meta name="keywords" content="Meme Token, Base Network, Create Token, Cryptocurrency, Blockchain, MetaMask" />
      </Helmet>

      {/* Sidebar komponens */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <header className="navbar">
        <div className="left-section">
          <button onClick={toggleSidebar} className="btn btn-secondary">☰ Networks</button>
        </div>
        <div className="right-section">
          <div className="metamask-connect top-right">
            {account ? (
              <p>Connected: {account.slice(0, 6)}...{account.slice(-4)}</p>
            ) : (
              <button onClick={connectMetaMask} className="btn btn-primary d-flex align-items-center">
                <img src={MetaMaskLogo} alt="MetaMask" className="metamask-logo" />
                <span className="ms-2">Connect MetaMask</span>
              </button>
            )}
          </div>
        </div>
      </header>

      <div className="base-logo-section">
        <img src={BaseLogo} alt="Base Logo" />
        <p>Available on the Base network</p>
      </div>

      <div className="card p-4 shadow-lg" style={{ maxWidth: '500px', width: '100%' }}>
        <h1 className="text-center mb-4">Create Your Meme Token</h1>
        <div className="form-group mb-3">
          <label className="form-label">Token Name</label>
          <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="e.g., MyMemeToken" />
        </div>
        <div className="form-group mb-3">
          <label className="form-label">Token Symbol</label>
          <input type="text" className="form-control" value={symbol} onChange={(e) => setSymbol(e.target.value)} placeholder="e.g., MMT" />
        </div>
        <div className="form-group mb-4">
          <label className="form-label">Total Supply</label>
          <input type="number" className="form-control" value={totalSupply} onChange={(e) => setTotalSupply(e.target.value)} placeholder="e.g., 1000000" />
        </div>

        <div className="form-group mb-4">
          <label className="form-label">Token Image (optional)</label>
          <input type="file" className="form-control" onChange={handleFileChange} />
        </div>

        <button className="btn btn-primary w-100" onClick={handleCreateToken}>Create Token</button>
        <p className="mt-3 text-center text-muted">{status}</p>

        <div className="accordion mt-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingPricing">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePricing" aria-expanded="false" aria-controls="collapsePricing">
                Pricing Information
              </button>
            </h2>
            <div id="collapsePricing" className="accordion-collapse collapse" aria-labelledby="headingPricing" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                The fee for creating token addresses is 0.00042 ETH. If an image is uploaded, the transaction fee will be 0.00084 ETH. Gas fees are variable but generally around 0.03 USD.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingLegal">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLegal" aria-expanded="false" aria-controls="collapseLegal">
                Legal Disclaimer and Liability Waiver
              </button>
            </h2>
            <div id="collapseLegal" className="accordion-collapse collapse" aria-labelledby="headingLegal" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                This website helps create simple meme tokens. We only provide the technology – you are fully responsible for the tokens you create and how they are used. We are not liable for any financial, legal, or other consequences that may result from your tokens. By using this platform, you agree that you create and use tokens at your own risk and are responsible for complying with the laws in your region.
              </div>
            </div>
          </div>
        </div>

        <Link to="/add-features" className="btn btn-secondary mt-3">Add Extra Features</Link>
      </div>

      <footer>
        <div className="row">
          <div className="col-md-8">
            {/* Fő tartalom helye */}
          </div>
          <div className="col-md-4" style={{ marginTop: '30px' }}>
            <FAQ /> {/* GYIK a jobb oldalon */}
          </div>
        </div>
      </footer>

      {/* Footer komponens hozzáadása */}
      <Footer />
    </div>
  );
}

export default BaseNetworkPage;
