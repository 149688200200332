import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './CryptoExchanges.module.css';

const exchangesData = [
    {
        name: "Binance",
        description: "Binance is one of the largest cryptocurrency exchanges, offering a wide variety of coins and advanced trading features for both beginners and professionals.",
        link: "binance",
        img: "/images/binance.png"
    },
    {
        name: "Coinbase",
        description: "Coinbase is a user-friendly exchange popular for its simplicity and security, making it ideal for beginners to buy, sell, and store cryptocurrencies.",
        link: "coinbase",
        img: "/images/coinbase.png"
    },
    {
        name: "Bybit",
        description: "Bybit is a prominent cryptocurrency exchange known for its high-leverage derivatives trading, user-friendly interface, and innovative trading tools, catering to both beginners and experienced traders.",
        link: "bybit",
        img: "/images/bybit.png"
    },
    {
        name: "OKX",
        description: "OKX is a global cryptocurrency exchange known for its extensive selection of financial services, including spot, futures, and margin trading, as well as DeFi and staking options.",
        link: "okx",
        img: "/images/okx.png"
    },
    {
        name: "Upbit",
        description: "Upbit is a major cryptocurrency exchange based in South Korea, renowned for its comprehensive trading options, strong security protocols, and regulatory compliance, catering primarily to the Asian market.",
        link: "upbit",
        img: "/images/upbit.png"
    },
    {
        name: "Kraken",
        description: "Kraken is a global cryptocurrency exchange that provides secure trading for a variety of digital assets, focusing on security, compliance, and a comprehensive trading experience for users of all skill levels.",
        link: "kraken",
        img: "/images/kraken.png"   
     },
     {
        name: "HTX",
        description: "HTX (formerly known as Huobi) is a globally recognized cryptocurrency exchange platform providing a secure and comprehensive suite of trading options, including spot, futures, staking, and DeFi services.",
        link: "htx",
        img: "/images/htx.png"
     },
     {
        name: "Bitget",
        description: "Bitget is a cryptocurrency exchange known for its focus on social trading, copy trading, and advanced derivatives, offering a comprehensive platform for traders at all levels.",
        link: "bitget",
        img: "/images/bitget.png"
     },
     {
        name: "Kucoin",
        description: "KuCoin is a global cryptocurrency exchange known for its extensive asset listings, innovative trading options, and strong community engagement, making it a preferred choice for diverse trading needs.",
        link: "kucoin",
        img: "/images/kucoin.png"
     },
     {
        name: "Gate IO",
        description: "Gate.io is a well-established cryptocurrency exchange platform offering extensive asset listings, advanced trading options, and innovative DeFi services to a global audience.",
        link: "gate io",
        img: "/images/gateio.png"
     },
     {
        name: "MEXC",
        description: "MEXC is a fast-growing cryptocurrency exchange known for its extensive range of digital assets, innovative trading options, and user-friendly platform that caters to both beginners and experienced traders.",
        link: "mexc",
        img: "/images/mexc.png"
     },
     {
        name: "Crypto com",
        description: "Crypto.com is a globally recognized cryptocurrency exchange and financial services platform, known for its extensive offerings in trading, staking, DeFi, and unique rewards programs.",
        link: "crypto com",
        img: "/images/crypto-com.png"
     }
    // További tőzsdéket itt adhatsz hozzá
];

function CryptoExchanges() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>Top Crypto Exchanges | Leading Cryptocurrency Trading Platforms</title>
                <meta name="description" content="Explore the top cryptocurrency exchanges where you can trade, buy, and sell digital assets securely. Find the best platform that suits your trading needs." />
                <meta name="keywords" content="crypto exchanges, Binance, Coinbase, Kraken, cryptocurrency trading, buy crypto, sell crypto" />
                <link rel="canonical" href="https://www.jsoroko.com/library/crypto-exchanges" />
            </Helmet>

            <header>
                <h1>Top Cryptocurrency Exchanges</h1>
                <p>Discover the leading cryptocurrency exchanges that offer secure platforms for trading, buying, and selling digital assets.</p>
            </header>

            <section className={styles.exchangesList}>
                {exchangesData.map((exchange, index) => (
                    <Link to={`/library/crypto-exchanges/${exchange.link}`} key={index} className={styles.exchangeCard}>
                        <img src={exchange.img} alt={`${exchange.name} Logo`} className={styles.exchangeImage} loading="lazy" />
                        <h2>{exchange.name}</h2>
                        <p>{exchange.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default CryptoExchanges;
