import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Library.module.css';
import Footer from './Footer'; // Footer komponens importálása

function Library() {
    const location = useLocation();
    const canonicalUrl = `https://jsoroko.com${location.pathname}`;

    return (
        <>
            <div className={styles.libraryContainer}>
                <Helmet>
                    <title>Crypto Resource Library | Comprehensive Crypto Tools and Platforms</title>
                    <link rel="canonical" href={canonicalUrl} />
                    <meta name="description" content="Explore the Crypto Resource Library – a curated collection of resources for cryptocurrency, including APIs, NFT trading platforms, P2E games, meme coin analytics, and crypto gambling sites. Perfect for developers, traders, and crypto enthusiasts alike." />
                    <meta name="keywords" content="crypto resource library, cryptocurrency API, blockchain tools, NFT platforms, P2E games, crypto market data, blockchain explorer, crypto gambling" />
                </Helmet>

                <header>
                    <h1>Crypto Resource Library</h1>
                    <p>Welcome to the ultimate Crypto Resource Library. Explore a curated selection of resources across the cryptocurrency ecosystem, from API tools for developers to platforms for NFT trading, P2E (Play-to-Earn) games, meme coin insights, and crypto gambling sites. Whether you're here to develop, invest, trade, or simply learn, our categorized resources provide everything you need to navigate the world of digital assets.</p>
                    <p>Select a category below to dive into resources that match your interests and project needs. Discover the latest platforms, gain insights, and expand your crypto experience with tools, analytics, and entertainment hubs.</p>
                </header>

                <section className={styles.categoryList}>
                    <Link to="/library/api" className={styles.categoryCard}>
                        <h2>API</h2>
                        <p>Explore various cryptocurrency APIs by category.</p>
                    </Link>
                    
                    <Link to="/library/nft-trading" className={styles.categoryCard}>
                        <h2>NFT Trading Platforms</h2>
                        <p>Discover popular platforms for buying, selling, and trading NFTs.</p>
                    </Link>

                    <Link to="/library/p2e-games" className={styles.categoryCard}>
                        <h2>P2E Games</h2>
                        <p>Explore Play-to-Earn games in the crypto space.</p>
                    </Link>

                    <Link to="/library/meme-coin-analytics" className={styles.categoryCard}>
                        <h2>Meme Coin Analytics</h2>
                        <p>Explore platforms for tracking and analyzing meme coins.</p>
                    </Link>

                    <Link to="/library/crypto-gambling-sites" className={styles.categoryCard}>
                        <h2>Crypto Gambling Sites</h2>
                        <p>Find popular cryptocurrency gambling platforms.</p>
                    </Link>

                    <Link to="/library/defi-apps" className={styles.categoryCard}>
                        <h2>Top DeFi Apps</h2>
                        <p>Explore top decentralized finance platforms for lending, borrowing, and trading.</p>
                    </Link>

                    <Link to="/library/blockchains" className={styles.categoryCard}>
                        <h2>Blockchain Networks</h2>
                        <p>Discover and explore various blockchain networks that power decentralized applications and innovations.</p>
                    </Link>

                    {/* Új Crypto Exchanges kategória hozzáadása */}
                    <Link to="/library/crypto-exchanges" className={styles.categoryCard}>
                        <h2>Crypto Exchanges</h2>
                        <p>Discover top cryptocurrency exchanges for trading, buying, and selling digital assets.</p>
                    </Link>
                    <Link to="/library/nodes" className={styles.categoryCard}>
                    <h2>Blockchain Nodes</h2>
                    < p>Explore essential blockchain nodes like Reth and Geth, critical for maintaining decentralized networks.</p>
                    </Link>
                    <Link to="/library/wallets" className={styles.categoryCard}>
                    <h2>Crypto Wallets</h2>
                    <p>Discover top crypto wallets for securely storing and managing your digital assets across various blockchain networks.</p>
                    </Link>

                </section>
            </div>

            {/* Footer hozzáadása a fő konténeren kívül */}
            <Footer />
        </>
    );
}

export default Library;
