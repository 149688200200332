import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './TopDeFiApps.module.css';

const defiAppsData = [
    {
        name: "Aave",
        description: "Aave is a decentralized platform for lending and borrowing cryptocurrencies, offering competitive interest rates and a secure, transparent environment.",
        link: "aave",
        img: "/images/aave.png"
    },
    {
        name: "Uniswap",
        description: "Uniswap is a decentralized exchange protocol that facilitates ERC-20 token trading on the Ethereum blockchain through an automated market maker model.",
        link: "uniswap",
        img: "/images/uniswap.png"
    },
    {
        name: "PancakeSwap",
        description: "PancakeSwap is a decentralized exchange on the BNB Smart Chain, providing BEP-20 token trading, yield farming, and staking with fast transactions and low fees.",
        link: "pancakeswap",
        img: "/images/pancakeswap.png"
    },
    {
        name: "CurveFinance",
        description: "Curve Finance is a decentralized exchange focused on stablecoin and similarly valued token trading, providing efficient swaps with minimal slippage and fees.",
        link: "curvefinance",
        img: "/images/curve.png"
    },
    {
        name: "SushiSwap",
        description: "SushiSwap is a decentralized exchange that enables users to trade cryptocurrencies, provide liquidity, and earn rewards through yield farming and staking, all under community governance.",
        link: "sushiswap",
        img: "/images/sushiswap.png"
    },
    {
        name: "Balancer",
        description: "Balancer is a decentralized protocol that enables users to create customizable liquidity pools and facilitates token swaps with an automated market maker model.",
        link: "balancer",
        img: "/images/balancer.png"
    },
    {
        name: "1inch",
        description: "1inch is a decentralized exchange aggregator that finds the best token swap rates across various DEXs, providing users with an efficient and cost-effective trading experience.",
        link: "1inch",
        img: "/images/1inch.png"
    },
    {
        name: "EtherFi",
        description: "EtherFi is a decentralized, non-custodial staking protocol for Ethereum, enabling users to stake ETH securely while retaining control of their assets.",
        link: "etherfi",
        img: "/images/etherfi.png"
    },
    {
        name: "Lido",
        description: "Lido is a decentralized liquid staking protocol, enabling users to stake assets on supported blockchains like Ethereum and receive tokenized staked assets that retain liquidity.",
        link: "lido",
        img: "/images/lido.png"
    },
    {
        name: "CoWSwap",
        description: "CoW Swap is a decentralized exchange (DEX) focused on secure, efficient trading through batch auctions and unique mechanisms to reduce gas fees and prevent MEV attacks.",
        link: "cowswap",
        img: "/images/cowswap.png"
    },
    {
        name: "Katana",
        description: "Katana is a decentralized exchange (DEX) on the Ronin blockchain, enabling users to trade various tokens within the Axie Infinity ecosystem with low fees and high efficiency.",
        link: "katana",
        img: "/images/katana.png"
    }

    // Additional DeFi apps can be added here as needed
];

function TopDefiApps() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>Top DeFi Apps | Leading Decentralized Finance Platforms</title>
                <meta name="description" content="Explore top decentralized finance (DeFi) platforms, providing innovative financial solutions for lending, borrowing, and trading in the crypto ecosystem." />
                <meta name="keywords" content="DeFi apps, decentralized finance, Aave, Uniswap, crypto lending, crypto borrowing, blockchain finance" />
                <link rel="canonical" href="https://www.jsoroko.com/library/defi-apps" />
            </Helmet>

            <header>
                <h1>Top Decentralized Finance Apps</h1>
                <p>Discover the leading decentralized finance applications offering secure, transparent services for lending, borrowing, and trading cryptocurrencies.</p>
            </header>

            <section className={styles.appsList}>
                {defiAppsData.map((app, index) => (
                    <Link to={`/library/defi-apps/${app.link}`} key={index} className={styles.appCard}>
                        <img src={app.img} alt={`${app.name} Logo`} className={styles.appImage} loading="lazy" />
                        <h2>{app.name}</h2>
                        <p>{app.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default TopDefiApps;
