// Learn.js
import React from 'react';
import './Learn.css';
import { Link, useLocation } from 'react-router-dom';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const articles = [
  { slug: 'What-is-Cryptocurrency', title: 'What is Cryptocurrency?', description: 'What is Cryptocurrency?' },
  { slug: 'What-is-Bitcoin', title: 'What is Bitcoin?', description: 'What is Bitcoin?' },
  { slug: 'What-is-Ethereum', title: 'What is Ethereum?', description: 'What is Ethereum?' },
  { slug: 'What-is-a-Blockchain', title: 'What is a Blockchain?', description: 'What is a Blockchain?' },
  { slug: 'How-to-Keep-Your-Crypto-Secure', title: 'How to Keep Your Crypto Secure', description: 'How to Keep Your Crypto Secure.' },
  { slug: 'What-are-Gas-Fees', title: 'What are Gas Fees?', description: 'What are Gas Fees?' },
  { slug: 'What-is-a-Stablecoin', title: 'What is a Stablecoin?', description: 'What is a Stablecoin?' },
  { slug: 'Forex-Trading-vs-Crypto-Trading-Which-is-Right-for-You?', title: 'Forex Trading vs. Crypto Trading: Which is Right for You?', description: 'Forex Trading vs. Crypto Trading: Which is Right for You?' },
  { slug: 'What-is-NFT-Art', title: 'What is NFT Art?', description: 'What is NFT Art?' },
  { slug: 'What-is-a-Crypto-Wallet', title: 'What is a Crypto Wallet?', description: 'What is a Crypto Wallet?' },
  { slug: '5-Applications-of-Blockchain-in-the-Supply-Chain-Industry', title: '5 Applications of Blockchain in the Supply Chain Industry', description: '5 Applications of Blockchain in the Supply Chain Industry' },
  { slug: 'How-Do-Cryptocurrency-Miners-Work', title: 'How Do Cryptocurrency Miners Work?', description: 'How Do Cryptocurrency Miners Work?' },
  { slug: 'What-is-a-Memecoin', title: 'What is a Memecoin?', description: 'What is a Memecoin?' },
  { slug: 'What-is-the-Difference-Between-a-Coin-and-a-Token', title: 'What is the Difference Between a Coin and a Token?', description: 'What is the Difference Between a Coin and a Token?' },
  { slug: 'What-are-Crypto-Whales', title: 'What are Crypto Whales?', description: 'What are Crypto Whales?' },
];

const Learn = () => {
  const location = useLocation();
  const canonicalUrl = `https://jsoroko.com${location.pathname}`;

  return (
    <>
      <Helmet>
        <title>Learn About Cryptocurrency: Comprehensive Guides and Insights</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content="Explore our extensive collection of articles on cryptocurrency, including Bitcoin, Ethereum, blockchain technology, trading strategies, security tips, and more. Enhance your understanding of the crypto world with in-depth guides and essential information." />
      </Helmet>
      <div className="learn-container">
        <h1>Learn</h1>
        <p>Explore the basics of blockchain, cryptocurrency, DeFi, and more through short articles.</p>
        <div className="article-list">
          {articles.map((article) => (
            <Link key={article.slug} to={`/learn/${article.slug}`} className="article-link">
              <div className="article">
                <h2 className="article-title">{article.title}</h2>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Learn;
