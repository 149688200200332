import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './MemeCoinAnalytics.module.css';

const memeCoinPlatformsData = [
    {
        name: "DEXTools",
        description: "DEXTools is a leading platform for real-time data and analysis in the decentralized exchange (DEX) space, offering traders deep market insights and advanced trading tools.",
        link: "dextools",
        img: "/images/dextools.png"
    },
    {
        name: "PooCoin",
        description: "A specialized platform for real-time analytics of meme coins and tokens on Binance Smart Chain, providing transparency and tracking for smaller tokens.",
        link: "poocoin",
        img: "/images/poocoin.png"
    },
    {
        name: "Pump.fun",
        description: "A Solana-based platform that allows users to easily create and launch their own tokens with fair launch mechanics and community-focused features.",
        link: "pumpfun",
        img: "/images/pumpfun.png"
    },
    {
        name: "GeckoTerminal",
        description: "A robust platform providing real-time analytics for cryptocurrencies traded on decentralized exchanges, offering insights into prices, trading volumes, and liquidity.",
        link: "geckoterminal",
        img: "/images/geckoterminal.png"
    },
    {
        name: "DEXScreener",
        description: "A real-time analytics platform for decentralized exchanges, providing insights into prices, trading volumes, and trends across multiple blockchain networks.",
        link: "dexscreener",
        img: "/images/dexscreener.png"
    }
    // Add more platforms as needed
];

function MemeCoinAnalytics() {
    return (
        <div className={styles.pageContainer}>
            <Helmet>
                <title>Meme Coin Analytics | Meme Coin Tracking and Insights</title>
                <meta name="description" content="Discover platforms dedicated to meme coin analytics, offering real-time data, trading insights, and tracking for popular meme coins." />
                <meta name="keywords" content="meme coin analytics, meme coin tracking, crypto analytics, DEX tools, PooCoin, Bogged Finance" />
                <link rel="canonical" href="https://www.jsoroko.com/library/meme-coin-analytics" />
            </Helmet>

            <header>
                <h1>Meme Coin Analytics</h1>
                <p>Explore top platforms for meme coin analytics and tracking, providing insights and real-time data for various meme coins on popular networks.</p>
            </header>

            <section className={styles.gamesList}>
                {memeCoinPlatformsData.map((platform, index) => (
                    <Link to={`/library/meme-coin-analytics/${platform.link}`} key={index} className={styles.gameCard}>
                        <img src={platform.img} alt={`${platform.name} Logo`} className={styles.gameImage} loading="lazy" />
                        <h2>{platform.name}</h2>
                        <p>{platform.description}</p>
                    </Link>
                ))}
            </section>
        </div>
    );
}

export default MemeCoinAnalytics;
